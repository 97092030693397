import { type OktaAuth, type OktaAuthOptions } from '@okta/okta-auth-js';

const oktaClientOptions: OktaAuthOptions = {
    pkce: true,
    redirectUri: '/implicit/callback',
    clientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
    issuer: process.env.NEXT_PUBLIC_OKTA_ISSUER,
    scopes: ['openid', 'profile', 'email'],
    tokenManager: {
        expireEarlySeconds: 20,
        autoRemove: true,
        autoRenew: true
    }
};

const oktaPromise = import('@okta/okta-auth-js');

export class OktaAuthClient {
    public static instance: OktaAuth | null = null;

    public static async getInstance(): Promise<OktaAuth> {
        if (typeof window === 'undefined') {
            // we never ssr authenticated requests so this is always false
            return Promise.resolve({ isAuthenticated: () => Promise.resolve(false) } as OktaAuth);
        }
        if (!OktaAuthClient.instance) {
            const oktaModule = await oktaPromise;
            OktaAuthClient.instance = new oktaModule.OktaAuth(oktaClientOptions);
            return OktaAuthClient.instance;
        }

        return OktaAuthClient.instance;
    }
}
