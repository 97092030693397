import { CustomErrorEvent, genericErrorToken, genericEvent } from './errorConstants';

/**
 * Adds the generic error event listener to the DOM.
 * @param handleErrorEvent - What to do when an error is encountered
 */
export const errorEventListener = (handleErrorEvent: (event: CustomEvent<CustomErrorEvent>) => void): (() => void) => {
    if (typeof window !== 'undefined') {
        window.addEventListener(genericErrorToken, handleErrorEvent as EventListener);
        return () => {
            window.removeEventListener(genericErrorToken, handleErrorEvent as EventListener);
        };
    }
    return () => {};
};

/**
 * Dispatch a generic error
 */
export const dispatchError = (_errorMessage?: unknown): void => {
    console.error('dispatchError', _errorMessage);
    if (typeof window !== 'undefined') {
        window.dispatchEvent(genericEvent(typeof _errorMessage === 'string' ? _errorMessage : undefined) as Event);
    }
};
