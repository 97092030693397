export interface Lwin18AssetMetadata {
    id: string;
    vintage: number;
    caseSize: number;
    sizeML: string;
    lwin11: string;
    formattedBottleSize: string;
    lwin16: string;
}

export interface Lwin16AssetMetadata {
    lwin11: string;
    id: string;
    vintage: number;
    sizeML: string;
    formattedBottleSize: string;
}

/** returns wine information that is within the lwin18 identifier. */
export const parseLWin18 = (lwin18: string): Lwin18AssetMetadata => {
    const size = lwin18?.slice(13, 18);
    const formatedSize = formatedSizeML(size);
    const lwin16 = lwin18?.slice(0, 11) + size;

    return {
        lwin11: lwin18?.slice(0, 11),
        id: lwin18?.slice(0, 7),
        vintage: Number.parseInt(lwin18?.slice(7, 11), 10),
        caseSize: Number.parseInt(lwin18?.slice(11, 13), 10),
        sizeML: size,
        formattedBottleSize: formatedSize,
        lwin16
    };
};

/** returns wine information that is within lwin16 identifier */
export const parseLWin16 = (lwin16: string): Lwin16AssetMetadata => {
    const size = lwin16.slice(11, 16);
    const formatedSize = formatedSizeML(size);
    return {
        lwin11: lwin16.slice(0, 11),
        id: lwin16.slice(0, 7),
        vintage: Number.parseInt(lwin16.slice(7, 11), 10),
        sizeML: size,
        formattedBottleSize: formatedSize
    };
};

const formatedSizeML = (size: string): string => {
    switch (size) {
        case '00750':
            return '750ml';
        case '01500':
            return '1.5L';
        case '03000':
            return '3L';
        case '04500':
            return '4.5L';
        case '06000':
            return '6L';
        case '09000':
            return '9L';
        default:
            return size;
    }
};
