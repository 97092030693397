import { query } from '~/networking';

const algoliasearch = import('algoliasearch');

export interface AlgoliaInitializationConfig {
    getSearchId: () => string;
    getSearchKey: () => string;
    getSearchIndex: () => string;
}

export const makeAlgoliaApis = async (config: AlgoliaInitializationConfig) => {
    const searcher = await algoliasearch;
    const algolia = searcher.default(config.getSearchId(), config.getSearchKey());
    const algoliaIndex = algolia.initIndex(config.getSearchIndex());
    return {
        query: query(algoliaIndex)
    };
};

export type AlgoliaApis = ReturnType<typeof makeAlgoliaApis>;
