import axios from 'axios';

import Endpoints from '~/constants/endpoints';
import { CreateTradingPortfolioResponse } from '~/models/CreateTradingPortfolioResponse';

/** create trading portfolioId */
export const CreateTradeProfile = async (jwt: string) => {
    return axios
        .post<CreateTradingPortfolioResponse>(
            Endpoints.CREATE_TRADING_PROFILE,
            {},
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Accept-Language': 'en, zh'
                }
            }
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.error(error);
        });
};
