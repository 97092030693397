import { GetTradingPortfolioBottlesResponse } from '~/networking/api/trading/responses';
import { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getTradingPortfolioBottles =
    ({ getHeaders, getRequestUrl, getTradingPortfolioId }: MakeTradingRequestOptions) =>
    async (): Promise<Response<GetTradingPortfolioBottlesResponse>> => {
        const headers = getHeaders(true);
        const getTradingPortfolioBottlesEndpoint = getRequestUrl(`/v1/portfolio/${getTradingPortfolioId()}/bottles`);

        return httpRequest.get(getTradingPortfolioBottlesEndpoint, { headers });
    };
