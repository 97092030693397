import { createRoute } from 'next-typed-routes';

import { MarketingRoutes } from './marketing';

export enum BaseRoutes {
    Dashboard = '/dashboard',
    Managed = '/managed',
    Whiskey = '/whiskey',
    Trade = '/trade',
    Account = '/account',
    Compare = '/compare',
    Invite = '/invite',
    Help = '/help',
    Login = '/login',
    CreateAccount = '/new-user/create-account',
    CreateAccountPhone = '/new-user/create-account-phone',
    ResetPassword = '/request-password-reset',
    ScheduledWines = '/scheduled-wines',
    PrivacyPolicy = '/privacy-policy',
    TermsConditions = '/terms-conditions',
    Onboarding = '/onboarding',
    TrackRecord = '/track-record',
    // WhyWine = '/why-wine',
    WhyWhiskey = '/whiskey-investment',
    AboutUs = '/about-us',
    Home = '/',
    Upsell = '/offer', // nothing at the index, will require a slug
    Blog = '/blog' // nothing at the index, will require a slug
}

/** General links */
export const baseRoutes = {
    dashboard: createRoute(BaseRoutes.Dashboard),
    contactUs: createRoute(MarketingRoutes.ContactUs),
    community: createRoute(MarketingRoutes.Community),
    signup: createRoute(MarketingRoutes.SignUp),
    faq: createRoute(MarketingRoutes.Help),
    help: createRoute(BaseRoutes.Help),
    compare: createRoute(BaseRoutes.Compare),
    invite: createRoute(BaseRoutes.Invite),
    managed: createRoute(BaseRoutes.Managed),
    trade: createRoute(BaseRoutes.Trade),
    whiskey: createRoute(BaseRoutes.Whiskey),
    account: createRoute(BaseRoutes.Account),
    login: createRoute(BaseRoutes.Login),
    createAccount: createRoute(BaseRoutes.CreateAccount),
    createAccountPhone: createRoute(BaseRoutes.CreateAccountPhone),
    resetPassword: createRoute(BaseRoutes.ResetPassword),
    scheduledWines: createRoute(BaseRoutes.ScheduledWines),
    privacyPolicy: createRoute(BaseRoutes.PrivacyPolicy),
    termsConditions: createRoute(BaseRoutes.TermsConditions),
    onboarding: createRoute(BaseRoutes.Onboarding),
    trackRecord: createRoute(BaseRoutes.TrackRecord),
    aboutUs: createRoute(BaseRoutes.AboutUs),
    whyWhiskey: createRoute(BaseRoutes.WhyWhiskey),
    home: createRoute(BaseRoutes.Home),
    // whyWine: createRoute(BaseRoutes.WhyWine),
    upsell: createRoute(BaseRoutes.Upsell),
    blog: createRoute(BaseRoutes.Blog)
};

/**
 * Get the login route with a destination
 */
export function getLoginRoute(destination?: string): string {
    if (!destination) {
        return baseRoutes.login.href;
    }
    return `${baseRoutes.login.href}?callback_pathname=${encodeURIComponent(destination)}`;
}

/**
 * Get the login route that always returns to a destination, defaulting to the current page
 */
export function getLoginReturnRoute(destination?: string): string {
    if (!destination) {
        // eslint-disable-next-line no-param-reassign
        destination = window.location.pathname + window.location.search;
    }
    return `${baseRoutes.login.href}?callback_pathname=${encodeURIComponent(destination)}`;
}
