import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export interface UserWithdrawalPayload {
    amount: {
        amount: number;
        currency: string;
    };
    portfolioID: string;
}

export const postUserWithdrawal = ({ getHeaders, getUserId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (payload: UserWithdrawalPayload): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/withdrawal`);

        const result = await httpRequest.post(httpRequestUrl, {
            headers,
            body: payload
        });
        return result;
    };
};
