import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

/** Create recurring or one time deposit charge. */
export const createPaymentSubscription =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (
        portfolioID: string,
        accountID: string,
        amount: number,
        interval: string,
        startDate?: string,
        escrowID?: string
    ): Promise<Response<void>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/payment/${getUserId()}/portfolio/${portfolioID}/charge`);

        return httpRequest.post(httpRequestUrl, {
            headers,
            body: { portfolioID, accountID, amount, interval, startDate, escrowID }
        });
    };
