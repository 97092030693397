import { type EventHint, type Breadcrumb, getCurrentScope } from '@sentry/nextjs';

import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import type { CaptureContext, SeverityLevel } from '@sentry/types';

interface NetworkingContext {
    userId?: string;
    managedPortfolioId?: string;
    tradingPortfolioId?: string;
    currency?: string;
}

const categoryMapper: { [key: string]: string } = {
    [AnalyticsEventNames.CancelBuy]: 'order',
    [AnalyticsEventNames.ClickBuy]: 'order',
    [AnalyticsEventNames.ConfirmBuy]: 'order',
    [AnalyticsEventNames.ConfirmBuyOrder]: 'order',
    [AnalyticsEventNames.CompleteBuy]: 'order',
    [AnalyticsEventNames.ReviewBuyOrder]: 'order',

    [AnalyticsEventNames.ReviewSellOrder]: 'order',
    [AnalyticsEventNames.CancelSell]: 'order',
    [AnalyticsEventNames.CancelOrder]: 'order',
    [AnalyticsEventNames.SelectSell]: 'order',
    [AnalyticsEventNames.ConfirmSell]: 'order',
    [AnalyticsEventNames.ConfirmSellOrder]: 'order',

    [AnalyticsEventNames.DepositAdded]: 'deposit',
    [AnalyticsEventNames.DepositAmountAdded]: 'deposit',
    [AnalyticsEventNames.DepositInitiated]: 'deposit',
    [AnalyticsEventNames.NewPaymentMethodAdded]: 'deposit',
    [AnalyticsEventNames.PaymentMethodLinked]: 'deposit',
    [AnalyticsEventNames.PreviewDepositClicked]: 'deposit'
};

export const SentryHelper = {
    addBreadCrumb(breadCrumb: Breadcrumb) {
        if (!breadCrumb.category && categoryMapper[breadCrumb.event_id as AnalyticsEventNames]) {
            // eslint-disable-next-line no-param-reassign
            breadCrumb.category = categoryMapper[breadCrumb.event_id as AnalyticsEventNames];
        }
        getCurrentScope().addBreadcrumb(breadCrumb);
    },
    captureMessage(error: string, level?: SeverityLevel, hint?: EventHint) {
        getCurrentScope().captureMessage(error, level, hint);
    },
    captureException(error: unknown, context?: CaptureContext) {
        const hint = { captureContext: context };
        getCurrentScope().captureException(error, hint);
    },
    addNetworkingContext(newContext: NetworkingContext) {
        getCurrentScope().setContext('networking', {
            ...newContext
        });
    },
    setUser(userId?: string) {
        if (userId) {
            getCurrentScope().setUser({ id: userId });
        } else {
            getCurrentScope().setUser(null);
        }
    }
};
