import type { CreateStripePaymentIntentResponse } from '~/networking/api/managed/responses/payments/CreateStripePaymentIntentResponse';
import type { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const createStripePaymentIntent =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (
        amount: number,
        paymentMethodType: string,
        paymentMethodID: string,
        portfolioID: string,
        escrowID?: string
    ): Promise<Response<CreateStripePaymentIntentResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/payment/${getUserId()}/portfolio/${portfolioID}/intent`);

        return httpRequest.post(httpRequestUrl, {
            headers,
            body: {
                amount,
                paymentMethodType,
                paymentMethodID,
                escrowID
            }
        });
    };
