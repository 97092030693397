import { CreatePlaidPaymentIntentResponse } from '~/networking/api/managed/responses/payments';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const createPlaidPaymentIntent =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (
        publicToken: string,
        accountID: string,
        portfolioID: string
    ): Promise<Response<CreatePlaidPaymentIntentResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/payment/${getUserId()}/portfolio/${portfolioID}/stripe/ach`);

        return httpRequest.post(httpRequestUrl, {
            headers,
            body: { publicToken, accountID }
        });
    };
