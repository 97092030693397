import Cookies from 'js-cookie';

export const ReferralCookieId = 'referralinvite';
export const ReferralCookieConfig = { domain: '.vinovest.co', path: '/' };

export const enum ReferralStatus {
    NotLinked,
    Linked,
    Failed
}

const isDevelopment = process.env.NEXT_PUBLIC_APP_ENV === 'development';

export const ReferralCookieHelper = {
    getReferralCookie: () => {
        const value = Cookies.get(ReferralCookieId);
        return value;
    },
    setReferralCookie: (referralCode: string) => {
        Cookies.set(ReferralCookieId, referralCode);
    },
    removeReferralCookie: () => {
        Cookies.remove(ReferralCookieId, isDevelopment ? undefined : ReferralCookieConfig);
    }
};
