export type WindowSize = 'desktop' | 'tablet' | 'mobile';

export const breakpoints = {
    desktop: 1135,
    tablet: 768,
    mobile: 0
};

export const minWidthQuery = {
    desktop: `(min-width: ${breakpoints.desktop}px)`,
    tablet: `(min-width: ${breakpoints.tablet}px)`,
    mobile: `(min-width: ${breakpoints.mobile}px)`
};
