import { CreateAchAccountResponse } from '~/networking/api/managed/responses/payments/CreateAchAccountResponse';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const createAchAccount =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (publicAccountToken: string, portfolioID: string): Promise<Response<CreateAchAccountResponse>> => {
        const headers = getHeaders(true);
        const createAchAccountEndpoint = getRequestUrl(
            `/v1/payment/${getUserId()}/portfolio/${portfolioID}/stripe/ach`
        );

        return httpRequest.post(createAchAccountEndpoint, {
            headers,
            body: { publicToken: publicAccountToken, accountId: getUserId() }
        });
    };
