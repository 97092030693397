import { GetUserPaymentMethodsResponse } from '~/networking/api/managed/responses/payments/GetUserPaymentMethodsResponse';
import type { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getUserPaymentMethods =
    ({ getHeaders, getRequestUrl, getUserId, getPortfolioId }: MakeMangedRequestOptions) =>
    async (portfolioID?: string): Promise<Response<GetUserPaymentMethodsResponse>> => {
        const headers = getHeaders(true);
        const getUserPaymentMethodsEndpoint = getRequestUrl(
            `/v1/payment/${getUserId()}/portfolio/${portfolioID || getPortfolioId()}`
        );

        return httpRequest.get(getUserPaymentMethodsEndpoint, { headers });
    };
