import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const putLinkCashAwardReferral =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (referrerId: string): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const getPendingTransfersEndpoint = getRequestUrl(`/v2/user/${getUserId()}/referral`);

        return httpRequest.put(getPendingTransfersEndpoint, { headers, body: JSON.stringify({ referrerId }) });
    };
