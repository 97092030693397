import { TradingPortfolioBottleDetails } from '~/networking';

export const getTotalPortfolioValues = (bottles: TradingPortfolioBottleDetails[]) => {
    if (!bottles) {
        return;
    }
    const portfolioBottles = [...bottles];
    const totalValues = (portfolioBottles || []).reduce(
        (bottleTotals, { costbasis, quantity, totalvalue }) => {
            const updatedBottleTotals = { ...bottleTotals };
            updatedBottleTotals.bottleValue += totalvalue.amount;
            updatedBottleTotals.bottleQuantity += quantity;
            updatedBottleTotals.costBasis += costbasis.amount * quantity;
            return updatedBottleTotals;
        },
        { bottleValue: 0, bottleQuantity: 0, costBasis: 0 }
    );

    return {
        ...totalValues
    };
};
