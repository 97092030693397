import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export interface SendInviteEmailParameters {
    emails: string[];
}

export const postSendInviteEmails =
    ({ getUserId, getHeaders, getRequestUrl }: MakeMangedRequestOptions) =>
    async ({ emails }: SendInviteEmailParameters): Promise<Response<void>> => {
        const headers = getHeaders(true);
        const endpoint = getRequestUrl(`/v1/user/${getUserId()}/champagne`);
        return httpRequest.post(endpoint, { body: emails, headers });
    };
