import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export interface NewUserParameters {
    email: string;
    firstName: string;
    lastName: string;
    login: string;
    password: string;
    primaryPhone?: string;
    investmentStyle?: string;
}

interface ValidationError {
    message: string;
}

export const createNewUser =
    ({ getHeaders, getRequestUrl }: MakeMangedRequestOptions) =>
    async (newUser: NewUserParameters): Promise<Response<void | ValidationError>> => {
        const headers = getHeaders(false);
        const createUserProfileCommandEndpoint = getRequestUrl(`/v1/user`);

        return httpRequest.post(createUserProfileCommandEndpoint, { body: newUser, headers });
    };
