import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

import { GetOfferListings } from '../../responses/portfolio/GetOfferListings';

export const getOfferListings =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetOfferListings>> => {
        const headers = getHeaders(true);
        const getOfferListingsEndpoint = getRequestUrl(`/v1/offer/portfolio/${getPortfolioId()}`);

        return httpRequest.get(getOfferListingsEndpoint, { headers });
    };
