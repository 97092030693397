import { PortfolioDefinition } from '~/networking/api/managed/responses';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export interface MakeWhiskeyVestPortfolioParameters {
    userId: string;
}

export const createWhiskeyvestPortfolio =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async ({ userId }: MakeWhiskeyVestPortfolioParameters): Promise<Response<PortfolioDefinition>> => {
        const headers = getHeaders(true);
        const createUserProfileCommandEndpoint = getRequestUrl(`/v1/user/${getUserId()}/portfolio/whiskeyvest`);

        return httpRequest.post(createUserProfileCommandEndpoint, { body: { userID: userId }, headers });
    };
