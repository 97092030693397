import { useEffect } from 'react';

import { PosthogHelper } from '~/helpers/PosthogHelper';
import { useAuthentication } from '~/hooks/useAuthentication';
/** Identify posthog user based on their userId */
export const useIdentifyPosthogUser = () => {
    const { userId } = useAuthentication();
    useEffect(() => {
        !!userId && PosthogHelper.identify(userId);
    }, [userId]);
};
