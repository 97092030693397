import { useMemo } from 'react';

import { useGetUserPortfoliosInfo } from '~/hooks/queries/useGetUserPortfoliosInfo';
import { PortfolioTypes } from '~/models/PortfolioType';
import { PaymentMethod } from '~/networking';

import { useAllPaymentMethods } from './useAllPaymentMethods';

export const useDepositDestinationValues = (destinationPortfolio: PortfolioTypes) => {
    const stuff = useGetUserPortfoliosInfo();
    const { managed: managedInfo, trading: tradingInfo, whiskey: whiskeyInfo } = stuff;
    const { managed, trading, whiskey } = useAllPaymentMethods();

    const refetchPaymentMethods = (): void => {
        managed.refetch();
        trading.refetch();
        whiskey.refetch();
    };

    const savedPaymentMethods = useMemo(() => {
        switch (destinationPortfolio) {
            case PortfolioTypes.managed:
                return managed?.payments ?? [];
            case PortfolioTypes.trading:
                return trading?.payments ?? [];
            case PortfolioTypes.whiskey:
                return whiskey?.payments ?? [];
            default:
                return [] as unknown as PaymentMethod[];
        }
    }, [destinationPortfolio, managed.payments, trading.payments, whiskey.payments]);

    const destinationPortfolioId = useMemo(() => {
        switch (destinationPortfolio) {
            case PortfolioTypes.trading:
                return tradingInfo?.id ?? '';
            case PortfolioTypes.managed:
                return managedInfo?.id ?? '';
            case PortfolioTypes.whiskey:
                return whiskeyInfo?.id ?? '';
            default:
                return '';
        }
    }, [destinationPortfolio, managedInfo?.id, whiskeyInfo?.id, tradingInfo?.id]);

    return {
        savedPaymentMethods,
        refetchPaymentMethods,
        destinationPortfolioId,
        paymentMethodsLoaded: managed.isSuccess && trading.isSuccess && whiskey.isSuccess
    };
};
