import { makeAutoObservable } from 'mobx';

class RouteStore {
    prevRoute: string | null = null;

    constructor() {
        makeAutoObservable(this); // tracking state changes
    }

    // Set the previous route
    setPrevRoute(route: string) {
        this.prevRoute = route;
    }
}

export const routeStore = new RouteStore();
