import { MakeMangedRequestOptions } from '~/networking/client';
import { InvestmentStrategy } from '~/networking/constants';
import { httpRequest, Response } from '~/networking/utils';

export type UserHorizon = 'short' | 'medium' | 'long';
export interface PutUserProfilePayload {
    investingStyle: Lowercase<InvestmentStrategy>;
    consideredStartingInvestment: number;
    anticipatedCashAccess: 'immediately' | 'month' | 'not sure';
}

export const putUserProfile = ({ getHeaders, getUserId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (investmentProfile: PutUserProfilePayload): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/profile`);

        const result = await httpRequest.put(httpRequestUrl, {
            headers,
            body: JSON.stringify({ investmentProfile })
        });
        return result;
    };
};
