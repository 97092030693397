import type { AccessToken, IDToken } from '@okta/okta-auth-js';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { SessionStorageHelper } from '~/helpers/StorageHelper';
import { ClientsideAuthentication } from '~/stores/ClientsideAuthentication';

interface AuthenticationContext {
    isAuthenticated: boolean;
    isAuthLoading: boolean;
    jwt?: string;
    userId?: string;
    tokenInfo?: { accessToken?: AccessToken; idToken?: IDToken };
    email?: string;
    authStore: ClientsideAuthentication;
    firstName?: string;
    lastName?: string;
}

/**
 * Hook to check authentication status client side.
 */
export const useAuthentication = (mustAuthenticate?: boolean) => {
    const router = useRouter();
    const store = ClientsideAuthentication.getInstance();
    const { callback_pathname: callbackParam } = router.query;

    if (callbackParam != null) {
        SessionStorageHelper.setItem('callback_pathname', callbackParam);
    }

    return useObserver((): AuthenticationContext => {
        if (!store) {
            throw new Error("Can't useAuthentication without a ClientsideAuthentication store");
        }

        const { isAuthenticated, jwt, userId, email, firstName, lastName, loading } = store;

        if (mustAuthenticate && typeof window !== 'undefined' && !isAuthenticated && !loading) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            router.push(
                `/login?callback_pathname=${encodeURIComponent(window.location.pathname + window.location.search)}`
            );
        }

        return {
            isAuthenticated,
            isAuthLoading: loading,
            jwt: jwt || '',
            userId,
            email,
            authStore: store,
            firstName,
            lastName
        };
    });
};
