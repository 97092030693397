import { GetUniqueWireResponse } from '~/networking/api/managed/responses/payments/GetUniqueWireResponse';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getUniqueWireInformation =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (portfolioId: string): Promise<Response<GetUniqueWireResponse>> => {
        const headers = getHeaders(true);
        const createAchAccountEndpoint = getRequestUrl(`/v1/payment/${getUserId()}/portfolio/${portfolioId}/wire`);

        return httpRequest.get(createAchAccountEndpoint, {
            headers
        });
    };
