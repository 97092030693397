/** Throttle a function to only be called one time every
 * @param functionToThrottle - function you would like to throttle
 * @param timeLimitInMs - how long you want to pause the function
 * @param context - context the function is run in, usually ```this```
 */

export const throttle = (functionToThrottle: (...args: any[]) => any, timeLimitInMs: number, context: any) => {
    let previousFunctionCallTime: number;

    return (...functionArguments: any[]) => {
        if (!previousFunctionCallTime) {
            functionToThrottle.apply(context, functionArguments);
            previousFunctionCallTime = Date.now();
        }
        if (Date.now() - previousFunctionCallTime > timeLimitInMs) {
            functionToThrottle.apply(context, functionArguments);
            previousFunctionCallTime = Date.now();
        }
    };
};
