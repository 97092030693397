/**
 * Time ranges supported by the portfolio performance graph
 * @see {@link https://github.com/Vinovest/backend/blob/b7e07eacc0f862269b9909b29eb76ca33f960137/pkg/internal/ranges/ranges.go#L11-L38 TimeRanges}
 */
export enum PortfolioPerformanceRanges {
    OneWeek = '1w',
    TwoWeeks = '2w',
    OneMonth = '1m',
    ThreeMonths = '3m',
    SixMonths = '6m',
    OneYear = '1y',
    TwoYears = '2y',
    ThreeYears = '3y',
    All = 'all',
    None = 'none'
}
