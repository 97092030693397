import { type PostHog, type Properties } from 'posthog-js';

import { AnalyticsEventMap } from '~/constants/AnalyticsEventMap';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import { useEffect, useState } from 'react';

const posthogPromise = import('posthog-js');

function initError(error: unknown) {
    console.error('Posthog init error', error);
    SentryHelper.captureException(error);
}

/** Helper class used to interact with Posthog to track user analytics events. */
export class PosthogHelper {
    private static initialized: PostHog | null = null;

    public static async init(): Promise<PostHog> {
        return posthogPromise
            .then((posthogModule) => {
                const posthog = posthogModule.default;
                window.Vino.posthog = posthog;
                if (
                    !PosthogHelper.initialized &&
                    typeof window !== 'undefined' &&
                    process.env.NEXT_PUBLIC_POSTHOG_KEY
                ) {
                    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
                        save_referrer: true,
                        autocapture: true,
                        opt_out_capturing_by_default: false,
                        opt_out_persistence_by_default: false,
                        disable_persistence: false,
                        persistence: 'localStorage+cookie',
                        api_host: 'https://app.posthog.com',
                        debug: process.env.NEXT_PUBLIC_APP_ENV !== 'production',
                        loaded: (pg) => {
                            pg.opt_in_capturing();
                        }
                    });

                    PosthogHelper.initialized = posthog;
                }

                return posthog;
            })
            .catch((error) => {
                console.error('Posthog init error', error);
                throw error;
            });
    }

    /**
     *
     * @param event
     * @param payload
     */
    public static identify(userId: string, userPropertiesToSet?: Properties): void {
        this.init()
            .then((posthog) => {
                posthog.identify(userId, userPropertiesToSet);
            })
            .catch(initError);
    }

    /**
     *
     * @param event
     * @param payload
     */
    public static captureEvent<T extends AnalyticsEventMap, K extends keyof AnalyticsEventMap>(
        event: K,
        payload?: T[K],
        options?: { transport: 'XHR' | 'sendBeacon' }
    ): void {
        this.init()
            .then((posthog) => {
                posthog.capture(String(event), payload ?? {}, options);
            })
            .catch(initError);
    }

    /**
     * low level capture method, use captureEvent instead
     *
     * @param event
     * @param payload
     */
    public static capture(
        event: string,
        payload?: Parameters<PostHog['capture']>[1],
        options?: Parameters<PostHog['capture']>[2]
    ): void {
        this.init()
            .then((posthog) => {
                posthog.capture(String(event), payload, options);
            })
            .catch(initError);
    }

    /**
     * Checks for feature flag availabilty for a user and runs the provided callback when ready.
     * @param callback Callback to be run after the feature flags are ready.
     * @returns
     */
    public static onFeatureFlag(callback: Parameters<PostHog['onFeatureFlags']>[0]): void {
        this.init()
            .then((posthog) => {
                return posthog.onFeatureFlags(callback);
            })
            .catch(initError);
    }

    /**
     * Gets the variant of feature. Useful for multivariate tests. Must be called from within onFeatureFlag callback.
     *
     * @param featureKey - name of the experiment/feature being tested
     * @param options - to turn off sending event to posthog on feature retreval
     */
    public static getFeatureFlag(
        featureKey: Parameters<PostHog['getFeatureFlag']>[0],
        options?: Parameters<PostHog['getFeatureFlag']>[1]
    ): ReturnType<PostHog['getFeatureFlag']> {
        return this.initialized!.getFeatureFlag(featureKey, options);
    }

    /**
     * Checks if a feature is enabled when A/B testing.  Must be called from within onFeatureFlag callback.
     *
     * @param featureKey - name of the experiment/feature being tested
     * @param options - to turn off sending event to posthog on feature retreval
     */
    public static isFeatureEnabled(
        featureKey: Parameters<PostHog['isFeatureEnabled']>[0],
        options?: Parameters<PostHog['isFeatureEnabled']>[1]
    ): ReturnType<PostHog['isFeatureEnabled']> {
        return this.initialized!.isFeatureEnabled(featureKey, options);
    }

    /**
     * Reload feature flags for a user.
     */
    public static reloadFeatureFlags(): void {
        this.init()
            .then((posthog) => {
                posthog.reloadFeatureFlags();
            })
            .catch(initError);
    }

    /**
     * this does not change the test evaluation but is a temporary override for the user
     */
    public static overrideFeatureFlag(flag: string, value: string): void {
        this.init()
            .then((posthog) => {
                posthog.featureFlags.override({ [flag]: value });
            })
            .catch(initError);
    }

    /**
     * Capture feature interaction event.
     * https://github.com/PostHog/posthog-js/blob/main/react/src/components/PostHogFeature.tsx
     */
    public static captureFeatureInteraction(flag: string): void {
        this.init()
            .then((posthog) => {
                posthog.capture('$feature_interaction', {
                    feature_flag: flag,
                    $set: { [`$feature_interaction/${flag}`]: true }
                });
            })
            .catch(initError);
    }

    /**
     * Capture feature view event.
     * https://github.com/PostHog/posthog-js/blob/main/react/src/components/PostHogFeature.tsx
     */
    public static captureFeatureView(flag: string): void {
        this.init()
            .then((posthog) => {
                posthog.capture('$feature_view', { feature_flag: flag });
            })
            .catch(initError);
    }

    public static setUserProperty(
        key: string,
        value: string | Record<string, string | number | Record<string, string | number>>
    ): Promise<void> {
        return this.init()
            .then((posthog) => {
                return new Promise<void>((resolve) => {
                    function callback() {
                        resolve();
                    }

                    posthog.people.set({ [key]: value }, undefined, callback);
                });
            })
            .catch(initError);
    }

    public static registerProperty(
        key: string,
        value: string | Record<string, string | number | Record<string, string | number>>
    ): void {
        this.init()
            .then((posthog) => {
                posthog.register({ [key]: value });
            })
            .catch(initError);
    }

    public static getUserProperty(key: string) {
        return this.init()
            .then((posthog) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return posthog.get_property(key);
            })
            .catch(initError);
    }
}

export const useFeatureFlag = (flag: string, options?: Parameters<PostHog['isFeatureEnabled']>[1]) => {
    const [bucket, setBucket] = useState<string | boolean | undefined>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        PosthogHelper.onFeatureFlag(() => {
            setBucket(PosthogHelper.getFeatureFlag(flag, options));
            setLoading(false);
        });
    }, [flag, options]);

    return { featureLoading: loading, featureBucket: bucket };
};
