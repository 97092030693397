import Cookies from 'js-cookie';

import { Currencies } from '~/constants/currencies';

/**
 * Get the users currency code. Defaults to USD.
 */
export function getCurrencyCode() {
    return Cookies.get('localCurrency') ?? Currencies.USD;
}
