import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

interface ImpactBookACallPayload {
    orderID: string;
    eventDate: string;
}

export const postImpactBookACall = ({ getHeaders, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (payload: ImpactBookACallPayload): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const endpoint = getRequestUrl(`/v1/user/bookedCall`);
        return httpRequest.post<unknown>(endpoint, { headers, body: JSON.stringify(payload) });
    };
};
