import type { GetUpsellOfferGroup } from '~/networking';
import type { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest } from '~/networking/utils/httpRequest';

export const getUpsellOfferGroup =
    ({ getHeaders, getRequestUrl }: MakeMangedRequestOptions) =>
    async (slug: string) => {
        const headers = getHeaders(false);
        const getOfferListingsEndpoint = getRequestUrl(`/public/v1/offer/group/${slug}`);

        return httpRequest.get<GetUpsellOfferGroup>(getOfferListingsEndpoint, { headers });
    };
