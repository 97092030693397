import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const patchUserAllocationPreferences = ({ getHeaders, getUserId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (allocationPreferences: string): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/allocationPreferences`);

        const result = await httpRequest.patch(httpRequestUrl, {
            headers,
            body: JSON.stringify({ allocationPreferences })
        });
        return result;
    };
};
