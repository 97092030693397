import { GetBankWireUsInformationResponse } from '~/networking/api/managed';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getBankWireUsInformation =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (portfolioID: string): Promise<Response<GetBankWireUsInformationResponse>> => {
        const headers = getHeaders(true);
        const getBankWireUsInformationEndpoint = getRequestUrl(
            `/v1/payment/${getUserId()}/portfolio/${portfolioID}/wire`
        );

        return httpRequest.get(getBankWireUsInformationEndpoint, { headers });
    };
