import { GetUserPreferencesResponse } from '~/networking/api/managed';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const updateUserPreferences =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (preferenceUpdate: Partial<GetUserPreferencesResponse>): Promise<Response<GetUserPreferencesResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/preferences`);

        return httpRequest.put(httpRequestUrl, { headers, body: preferenceUpdate });
    };
