import { useRouter } from 'next/router';

import { ZendeskHelper } from '~/helpers/ZendeskHelper';
import Script from 'next/script';

export const ZendeskScript = () => {
    const router = useRouter();

    if (!router) {
        return null;
    }
    if (!ZendeskHelper.isZendeskPage(router.pathname)) {
        return null;
    }

    return (
        <Script
            strategy={'afterInteractive'}
            id={'ze-snippet'}
            src={'https://static.zdassets.com/ekr/snippet.js?key=f3638add-3be0-43a1-88d7-3fa3a4ab2d5a'}
        />
    );
};
