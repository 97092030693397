import { useMutation } from 'react-query';

import { mutations } from '~/constants/mutationKeys';
import { useAuthentication } from '~/hooks/useAuthentication';
import { CreateTradingPortfolioResponse } from '~/models/CreateTradingPortfolioResponse';

import { CreateTradeProfile } from './fetchers';
/**
 * Creates a trading portfolio for a user if they don't already have one.
 */
export const useCreateTradingPortfolioMutation = () => {
    const { jwt } = useAuthentication();

    return useMutation([mutations.CreateTradeProfile, jwt], async (): Promise<CreateTradingPortfolioResponse> => {
        if (jwt) {
            const tradingPortfolio = (await CreateTradeProfile(jwt)) as CreateTradingPortfolioResponse;
            return tradingPortfolio;
        }
        return { id: '', userId: '', cashBalance: 0 };
    });
};
