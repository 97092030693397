import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export interface NewOfferListing {
    price: {
        amount: number;
        currency: string;
    };
    minPrice: {
        amount: number;
        currency: string;
    } | null;
    tradeID: string;
    fmvPeg: boolean;
}

export const createOfferListing =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (newOffer: NewOfferListing): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const createNewOfferEndpoint = getRequestUrl(`/v1/offer/portfolio/${getPortfolioId()}`);

        return httpRequest.post(createNewOfferEndpoint, { body: newOffer, headers });
    };
