import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export interface PutUserPasswordPayload {
    newPassword: string;
    oldPassword: string;
}

interface ErrorResponse {
    message: string;
}

export const putUserPassword = ({ getHeaders, getUserId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (updater: PutUserPasswordPayload): Promise<Response<ErrorResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/password`);

        const result = await httpRequest.put<ErrorResponse>(httpRequestUrl, {
            headers,
            body: JSON.stringify(updater)
        });
        return result;
    };
};
