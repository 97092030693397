import { GetOrdersResponse } from '~/networking/api/trading/responses';
import { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export interface GetOrdersParams {
    pageSize?: number;
    pageToken?: string;
    lwin16?: string;
    side?: 'buy' | 'sell';
    status?: 'active' | 'cancelled' | 'filled';
}

export const getOrders =
    ({ getHeaders, getRequestUrl, getTradingPortfolioId }: MakeTradingRequestOptions) =>
    async (params: GetOrdersParams): Promise<Response<GetOrdersResponse>> => {
        const headers = getHeaders(true);
        const urlParams = new URLSearchParams({
            pageSize: String(params.pageSize || ''),
            pageToken: params.pageToken || '',
            lwin16: params.lwin16 || '',
            side: params.side || '',
            status: params.status || ''
        });
        const getOrdersEndpoint = getRequestUrl(
            `/v1/portfolio/${getTradingPortfolioId()}/orders?${urlParams.toString()}`
        );

        return httpRequest.get(getOrdersEndpoint, { headers });
    };
