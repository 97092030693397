import { useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { VinovestNetworkingHelper } from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';
import { PortfolioTypes } from '~/models/PortfolioType';
import { GetActiveUserPortfolios, PortfolioDefinition } from '~/networking';

/** Query for fetching users portfolios  */
export const useGetUserPortfolios = () => {
    const { jwt: token, userId } = useAuthentication();

    return useQuery<GetActiveUserPortfolios | undefined>(
        [queries.getAllManagedPortfolios, userId],
        async () => {
            const { managed } = VinovestNetworkingHelper.getClient({ userId });
            const userPortfolios = await managed.getUserPortfolios();
            if (userPortfolios.ok) {
                return userPortfolios.data;
            }

            return {} as GetActiveUserPortfolios;
        },
        {
            enabled: Boolean(token && userId)
        }
    );
};

export type PortfolioInformation = { [K in PortfolioTypes]: PortfolioDefinition };

export const transformPortfoliosToKeys = (portfolios: PortfolioDefinition[]): PortfolioInformation => {
    return portfolios.reduce((acc, port) => {
        acc[port.type as PortfolioTypes] = port;
        return acc;
    }, {} as PortfolioInformation);
};
