import { useSavedPaymentMethod } from '~/hooks/payments/useSavedPaymentMethod';
import { useGetUserPortfoliosInfo } from '~/hooks/queries/useGetUserPortfoliosInfo';

export const useAllPaymentMethods = () => {
    const { managed, trading, whiskey } = useGetUserPortfoliosInfo();

    const {
        data: managedPaymentMethods,
        refetch: refetchManagedPaymentMethods,
        isSuccess: managedPaymentsSuccess
    } = useSavedPaymentMethod(managed?.id ?? '');

    const {
        data: tradingPaymentMethods,
        refetch: refetchTradingPaymentMethods,
        isSuccess: tradingPaymentsSuccess
    } = useSavedPaymentMethod(trading?.id ?? '');

    const {
        data: whiskeyPaymentMethods,
        refetch: refetchWhiskeyPaymentMethods,
        isSuccess: whiskeyPaymentsSuccess
    } = useSavedPaymentMethod(whiskey?.id ?? '');

    return {
        whiskey: {
            payments: whiskeyPaymentMethods?.paymentMethods,
            refetch: refetchWhiskeyPaymentMethods,
            isSuccess: whiskeyPaymentsSuccess
        },
        managed: {
            payments: managedPaymentMethods?.paymentMethods,
            refetch: refetchManagedPaymentMethods,
            isSuccess: managedPaymentsSuccess
        },
        trading: {
            payments: tradingPaymentMethods?.paymentMethods,
            refetch: refetchTradingPaymentMethods,
            isSuccess: tradingPaymentsSuccess
        }
    };
};
