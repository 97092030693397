export const genericErrorToken = 'vinovest-generic-error';

export interface CustomErrorEvent {
    errorMessage?: string;
}

export const genericEvent = (errorMessage?: string): Event | null => {
    if (typeof window !== 'undefined') {
        return new CustomEvent<CustomErrorEvent>(genericErrorToken, { detail: { errorMessage } });
    }
    return null;
};
