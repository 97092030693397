import type { CreateOfferReservationEscrow } from '~/networking';
import type { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest } from '~/networking/utils/httpRequest';

export const startReservedEscrow =
    ({ getHeaders, getRequestUrl }: MakeMangedRequestOptions) =>
    async (portfolioId: string, reservationIDs: string[]) => {
        const headers = getHeaders(true);
        const url = getRequestUrl(`/v1/offer/portfolio/${portfolioId}/reserved/escrow`);
        return httpRequest.post<CreateOfferReservationEscrow>(url, {
            headers,
            body: {
                reservationIDs
            }
        });
    };
