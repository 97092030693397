import { GetTradingPortfolioChargesResponse } from '~/networking/api/trading/responses';
import { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getTradingPortfolioCharges =
    ({ getHeaders, getRequestUrl, getTradingPortfolioId }: MakeTradingRequestOptions) =>
    async (pending?: boolean): Promise<Response<GetTradingPortfolioChargesResponse>> => {
        const headers = getHeaders(true);
        const getTradingPortfolioChargesEndpoint = getRequestUrl(
            `/v1/portfolio/${getTradingPortfolioId()}/charges${pending ? '?status=pending' : ''}`
        );

        return httpRequest.get(getTradingPortfolioChargesEndpoint, { headers });
    };
