import { GetActiveUserPortfolios } from '~/networking/api/managed/responses';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getUserPortfolios =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetActiveUserPortfolios>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/portfolio`);

        return httpRequest.get(httpRequestUrl, { headers });
    };
