import { getHostPathGenerator } from '../../getHostPathGenerator';
import { headerFactory } from '../../headerFactory';
import {
    ApiGenerationOptions,
    ApiTypes,
    MakeRequestsOptions,
    MakeTradingRequestOptions
} from '../../VinovestNetworkingClient';

export const tradingInitializationConfig = ({
    getAccessToken,
    getUserId,
    getTradingPortfolioId,
    getCurrencyCode
}: MakeTradingRequestOptions & ApiGenerationOptions): MakeTradingRequestOptions & {
    getHeaders: MakeRequestsOptions['getHeaders'];
} => ({
    getUserId,
    getRequestUrl: getHostPathGenerator({ type: ApiTypes.Trading }),
    getTradingPortfolioId,
    getHeaders: (_secure?: boolean, extraHeaders?: { [key: string]: string } | Headers) =>
        headerFactory({ getAccessToken, getCurrencyCode, secure: true, type: ApiTypes.Trading, headers: extraHeaders })
});
