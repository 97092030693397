import { LocalizedPricingData } from '~/networking/models/PortfolioPricingData';

/**
 * Values representing status of a user's portfolio. These values map to fundingStatus on the backend -
 * https://github.com/Vinovest/backend/blob/0340402a471c68351ae0478bee84d50d5e00ac73/pkg/stores/portfolio/model.go#L32
 */
export enum UserPortfolioStatus {
    liquidating = 'Liquidating',
    inactive = 'Inactive',
    active = 'Active'
}

/** Snapshot of a user's portfolio value on a given date */
export interface PricingData {
    /** Timestamp corresponding to given portfolio value */
    date: string;
    /** Value of a user's portfolio at a moment in time */
    price: number;
}

/**
 * General overview of a user's portfolio used to get a high-level
 * understanding of how a user's account has performed.
 */
export interface UserPortfolioOverview {
    /** Portfolio unique ID */
    id: string;
    /** Number of wine bottles in a user's portfolio */
    bottleCount: number;
    /** Amount of cash a user has in their account */
    cash: {
        /** Amount of cash in the user's account in cents */
        amount: number;
        /** Currency used to denominate cash in user's account */
        currency: string;
    };
    /**  */
    costBasis: {
        amount: number;
        currency: string;
    };
    /** Strategy used to manage a user's account - conservative, moderate, aggressive. */
    investmentStyle: string;
    /** Total value of a user's account including wine and cash. */
    positionTotal: {
        amount: number;
        currency: string;
    };
    /** Percentage of a user's account made up by their wine portfolio, excluding cash */
    positionTotalPercentage: number;
    /** Further details about the performance of this user's portfolio including fees */
    returns: {
        fees: string;
        netChange: string;
        ytdFees: string;
        ytdNetChange: string;
    };
    /** Value representing the status of the portfolio - liquidating, inactive, or active. */
    status: string;
    /** Detailed historic pricing information for a user's portfolio */
    totalHistory: LocalizedPricingData;
}
