import type { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

import type { ModifyTradingOrderQuantityOrPriceResponse } from '../../responses/portfolio/ModifyTradingOrderQuantityOrPriceResponse';

export const modifyTradingOrderQuantityOrPrice =
    ({ getHeaders, getRequestUrl, getTradingPortfolioId }: MakeTradingRequestOptions) =>
    /**
     * Modify existing bid/ask for quantity or price
     *
     * @param orderId - Id of the order to modify
     * @param modifyOrder - order parameters to change
     *
     * @remarks *PATCH /v1/portfolio/{portfolioId}/orders/{orderId}*
     */
    async (
        orderId: string,
        modifyOrder: { quantity: number; price: number }
    ): Promise<Response<ModifyTradingOrderQuantityOrPriceResponse>> => {
        const modifyTradingOrderQuantityOrPriceEndpoint = getRequestUrl(
            `/v1/portfolio/${getTradingPortfolioId()}/orders/${orderId}}`
        );
        const headers = getHeaders();

        return httpRequest.patch(modifyTradingOrderQuantityOrPriceEndpoint, { headers, body: modifyOrder });
    };
