import { createContext, useContext, useEffect, useState } from 'react';

import { breakpoints as defaultBreakpoints } from '~/constants/mediaQueries';
import { debounce } from '~/utils/debounce';

interface WindowSize {
    width?: number;
    height?: number;
}

interface UseWindowSizeHook extends WindowSize {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
}

interface BreakPoints {
    desktop: number;
    tablet: number;
    mobile: number;
}

export interface ServerUAProps {
    isDesktop: boolean;
    isMobile: boolean;
    isTablet: boolean;
}

export const ServerUAContext = createContext({
    isDesktop: false,
    isMobile: false,
    isTablet: false
});

type ChangeCallback = (this: MediaQueryList, ev: MediaQueryListEvent) => void;

export const useWindowSize = (breakPoints: BreakPoints = defaultBreakpoints): UseWindowSizeHook => {
    const serverContext = useContext(ServerUAContext);
    const [isDesktop, setIsDesktop] = useState(serverContext.isDesktop);
    const [isMobile, setIsMobile] = useState(serverContext.isMobile);
    const [isTablet, setIsTablet] = useState(serverContext.isTablet);

    useEffect(() => {
        const onResize = () => {
            const width = window.innerWidth ?? 0;
            if (width <= 450) {
                setIsMobile(true);
                setIsDesktop(false);
                setIsTablet(false);
            } else if (width < breakPoints.tablet) {
                setIsTablet(true);
                setIsMobile(false);
                setIsDesktop(false);
            } else {
                setIsDesktop(true);
                setIsMobile(false);
                setIsTablet(false);
            }
        };
        onResize();
        if (typeof ResizeObserver !== 'undefined') {
            const resizeObserver = new ResizeObserver(debounce(onResize, 100));
            resizeObserver.observe(document.body);
            return () => {};
        }

        const phone = window.matchMedia(`(max-width: 460px)`);
        const tablet = window.matchMedia(`(max-width: ${breakPoints.tablet}px) and (min-width: 460px)`);
        const desktop = window.matchMedia(`(min-width: ${breakPoints.desktop}px)`);
        const phoneCallback: ChangeCallback = (e) => {
            if (e.matches) {
                setIsMobile(true);
                setIsDesktop(false);
                setIsTablet(false);
            }
        };
        phone.addEventListener('change', phoneCallback);

        const tabletCallback: ChangeCallback = (e) => {
            if (e.matches) {
                setIsTablet(true);
                setIsMobile(false);
                setIsDesktop(false);
            }
        };
        tablet.addEventListener('change', tabletCallback);

        const desktopCallback: ChangeCallback = (e) => {
            if (e.matches) {
                setIsDesktop(true);
                setIsMobile(false);
                setIsTablet(false);
            }
        };
        desktop.addEventListener('change', desktopCallback);

        return () => {
            phone.removeEventListener('change', phoneCallback);
            tablet.removeEventListener('change', tabletCallback);
            desktop.removeEventListener('change', desktopCallback);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isMobile, isTablet, isDesktop };
};
