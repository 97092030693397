import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { routeStore } from '~/stores/RouteStore';

export const usePreviousRoute = () => {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            routeStore.setPrevRoute(router.asPath); // Update store
        };

        // Listen for route changes
        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange); // Cleanup
        };
    }, [router]);
};
