import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

interface ImpactSignupPayload {
    orderID: string;
    clickID: string;
}

export const postImpactSignup = ({ getHeaders, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (payload: ImpactSignupPayload): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const endpoint = getRequestUrl(`/v1/user/impact/signup`);
        return httpRequest.post<unknown>(endpoint, { headers, body: JSON.stringify(payload) });
    };
};
