import type { GetTradingPortfolioOrderByIdResponse } from '~/networking/api/trading/responses';
import type { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getTradingPortfolioOrderById =
    ({ getHeaders, getRequestUrl, getTradingPortfolioId }: MakeTradingRequestOptions) =>
    async (orderId: string): Promise<Response<GetTradingPortfolioOrderByIdResponse>> => {
        const headers = getHeaders(true);
        const getTradingPortfolioOrderByIdEndpoint = getRequestUrl(
            `/v1/portfolio/${getTradingPortfolioId()}/orders/${orderId}}`
        );

        return httpRequest.get(getTradingPortfolioOrderByIdEndpoint, { headers });
    };
