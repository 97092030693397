import { useQuery } from 'react-query';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { RegisterTrackingKey } from '~/components/views/Signup/keys';
import { queries } from '~/constants/queryKeys';
import { RegistrationCookieKeys } from '~/constants/RegistrationCookieKeys';
import { useLinkFreeFeesReferral } from '~/hooks/managed/useLinkFreeFeesReferral';
import { useTracking } from '~/hooks/tracking/useTracking';
import { useAuthentication } from '~/hooks/useAuthentication';
import { baseRoutes } from '~/routes/base';

import { LocalStorageHelper } from './StorageHelper';
import { type AnalyticsEventMap, AnalyticsEventNames } from '../tracking';

export const NewRegistrationTracking = () => {
    const { userId: userID, email } = useAuthentication();
    const { captureEvent } = useTracking();
    const { mutate } = useLinkFreeFeesReferral();
    const cookieId = `rg_complete:${userID}`;
    const isNewUser = LocalStorageHelper.getItem('is_new_user');
    const isNewUserPhone = LocalStorageHelper.getItem('is_new_user_phone');

    // Track a newly registered user
    const handleTracking = () => {
        // This key tells us if they used social vs email sign up
        const trackingKey = LocalStorageHelper.getItem<keyof AnalyticsEventMap>(RegisterTrackingKey);
        if (userID) mutate(userID);
        if (trackingKey) {
            captureEvent(trackingKey, { userID, email });
            captureEvent(AnalyticsEventNames.RegistrationComplete, {
                userID: userID ?? '',
                email: email ?? ''
            });
            LocalStorageHelper.removeItem(RegisterTrackingKey);
            if (trackingKey === AnalyticsEventNames.GoogleSignUpClick) {
                Cookies.set(RegistrationCookieKeys.ShowPhoneNumberSignup, 'true');
            }

            if (trackingKey === AnalyticsEventNames.AppleSignUpClick) {
                Cookies.set(RegistrationCookieKeys.ShowPhoneNumberSignup, 'true');
            }

            if (trackingKey === AnalyticsEventNames.EmailSignUpClick) {
                Cookies.set(RegistrationCookieKeys.ShowPhoneNumberSignup, 'true');
            }
        }
        // Set a cookie to prevent extra calls to this endpoint
        Cookies.set(cookieId, '1');
    };

    const router = useRouter();
    const hasRedirect = LocalStorageHelper.getItem('whiskeyRedirectUrl');
    useQuery(
        [queries.getIsNewUser, isNewUser],
        () => {
            if (isNewUser) {
                LocalStorageHelper.removeItem('is_new_user');
                handleTracking();
                if (isNewUserPhone) {
                    void router.push(baseRoutes.createAccountPhone.href);
                    LocalStorageHelper.removeItem('is_new_user_phone');
                }
                if (!hasRedirect && !isNewUserPhone) {
                    void router.push(baseRoutes.onboarding.href);
                }
            }
        },
        { enabled: Boolean(userID && !Cookies.get(cookieId)) }
    );

    return null;
};
