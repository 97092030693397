import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const patchBulkFmvpeg =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (fmvPeg: boolean): Promise<Response<void>> => {
        const headers = getHeaders(true);
        const patchBulkFmvpegPath = getRequestUrl(`/v1/offer/portfolio/${getPortfolioId()}/fmvpeg`);

        return httpRequest.patch(patchBulkFmvpegPath, { headers, body: JSON.stringify({ fmvPeg }) });
    };
