import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { PortfolioCharges, PortfolioChargesStatus } from '~/networking/models/PortfolioCharges';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getPortfolioCharges =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (portfolioId: string, status?: PortfolioChargesStatus): Promise<Response<PortfolioCharges>> => {
        const query = new URLSearchParams({ status } as Record<string, string>);
        const headers = getHeaders(true);
        const portfolioCharges = getRequestUrl(
            `/v1/payment/${getUserId()}/portfolio/${portfolioId}/charges${status ? `?${query}` : ''}`
        );

        return httpRequest.get(portfolioCharges, { headers });
    };
