import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export interface PutOfferListing {
    price: {
        amount: number;
        currency: string;
    };
    minPrice: {
        amount: number;
        currency: string;
    } | null;
    tradeID: string;
    fmvPeg: boolean;
}

export const putOfferListing =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (updateOffer: PutOfferListing): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const putOfferEndpoint = getRequestUrl(`/v1/offer/portfolio/${getPortfolioId()}`);

        return httpRequest.put(putOfferEndpoint, { body: updateOffer, headers });
    };
