import { CreateTradingPortfolioOrderResponse } from '~/networking/api/trading/responses';
import { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export interface CreateTradingPortfolioOrderOptions {
    /** User Id associated with the trade */
    userID: string;
    /** Lwine16 of the trade */
    lwin16: string;
    /** bid or ask for the trade */
    side: string;
    /** associated price of the bid or ask */
    price: number;
    /** quantity of the trade */
    quantity: number;
    /** time for the trade to remain active */
    timeInForce: string;
    /** client reference for the trade  */
    clientReference: string;
}

export const createTradingPortfolioOrder =
    ({ getHeaders, getTradingPortfolioId, getRequestUrl }: MakeTradingRequestOptions) =>
    async (postBody: CreateTradingPortfolioOrderOptions): Promise<Response<CreateTradingPortfolioOrderResponse>> => {
        const createTradingPortfolioOrderEndpoint = getRequestUrl(`/v1/portfolio/${getTradingPortfolioId()}/orders`);

        return httpRequest.post(createTradingPortfolioOrderEndpoint, { headers: getHeaders(), body: postBody });
    };
