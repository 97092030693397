import { ApiTypes, getHostname } from '~/networking';

export default {
    // Managed - Documents
    get GET_NORMALIZED_PRICE() {
        return `${getHostname(ApiTypes.Managed)}/v2/lwin/lwin11/{0}/historical`;
    },

    // Managed - Transactions
    get GET_MANAGED_PENDING_CHARGES() {
        return `${getHostname(ApiTypes.Managed)}/v1/portfolio/{0}/charges?status=pending`;
    },

    // Managed - Pricing
    get GET_HISTORICAL_PRICING() {
        return `${getHostname(ApiTypes.Managed)}/v2/lwin/lwin11/{0}/historical?range={1}`;
    },

    // Managed - User
    get USER_PROFILE_INFORMATION() {
        return `${getHostname(ApiTypes.Managed)}/v1/user/{0}/information`;
    },
    get USER_RESET_PASSWORD() {
        return `${getHostname(ApiTypes.Managed)}/v1/user/password/reset`;
    },

    // Managed - Portfolio
    get GET_USER_MANAGED_PORTFOLIO() {
        return `${getHostname(ApiTypes.Managed)}/v1/portfolio/{0}?range={1}`;
    },

    // Whiskey  - Portfolio
    get GET_WHISKEY_PENDING_CHARGES() {
        return `${getHostname(ApiTypes.Managed)}/v1/portfolio/{0}/charges?status=pending`;
    },

    // Trading  - Collections
    get POST_COLLECTION_ORDER() {
        return `${getHostname(ApiTypes.Trading)}/v1/collection`;
    },
    get GET_LAST_TRADE_COLLECTION_PRICE() {
        return `${getHostname(ApiTypes.Trading)}/v1/collection/trade/{0}`;
    },
    get GET_TRADING_COLLECTION() {
        return `${getHostname(ApiTypes.Trading)}/v1/collection/{0}`;
    },

    // Trading  - Market
    get GET_MARKET_BUY_SELL_ORDERS() {
        return `${getHostname(
            ApiTypes.Trading
        )}/v1/markets/directory?pageSize={0}&pageToken={1}&status={2}&sorting={3}`;
    },
    get GET_LAST_TRADE_PRICE() {
        return `${getHostname(ApiTypes.Trading)}/v1/markets/trades?lwin16s={0}`;
    },
    get GET_MARKET_PRICE() {
        return `${getHostname(ApiTypes.Trading)}/v1/markets?lwin16s={0}`;
    },

    // Trading  - Portfolio
    get CREATE_TRADING_PROFILE() {
        return `${getHostname(ApiTypes.Trading)}/v1/portfolio`;
    },
    get GET_PORTFOLIO_BOTTLES() {
        return `${getHostname(ApiTypes.Trading)}/v1/portfolio/{0}/bottles`;
    },
    get GET_ALL_PORTFOLIOS() {
        return `${getHostname(ApiTypes.Managed)}/v1/user/{0}/portfolio`;
    },
    get GET_PORTFOLIO_CHARGES() {
        return `${getHostname(ApiTypes.Trading)}/v1/user/{0}/portfolio/{1}/charges`;
    },
    get GET_PORTFOLIO_TRANSACTIONS() {
        return `${getHostname(ApiTypes.Trading)}/v1/portfolio/{0}/transactions`;
    },
    get GET_TRADING_PENDING_CHARGES() {
        return `${getHostname(ApiTypes.Trading)}/v1/portfolio/{0}/charges?status=pending`;
    },
    get GET_PORTFOLIO_ORDERS() {
        return `${getHostname(ApiTypes.Trading)}/v1/portfolio/{0}/orders?lwin16={1}`;
    },
    get POST_PORTFOLIO_ORDER() {
        return `${getHostname(ApiTypes.Trading)}/v1/portfolio/{0}/orders`;
    },

    // Trading  - Orders
    get DELETE_PORTFOLIO_ORDER() {
        return `${getHostname(ApiTypes.Trading)}/v1/portfolio/{0}/orders/{1}`;
    },

    get CMS_BASE() {
        return getHostname(ApiTypes.CMS);
    }
};
