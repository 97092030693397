import React, {
    createContext,
    Dispatch,
    type ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';

import { useManagedPortfolioQuery } from '~/hooks/queries/portfolios/managed/useManagedPortfolioQuery';
import { usePendingPortfolioTransactions } from '~/hooks/queries/portfolios/usePendingPortfolioTransactions';
import { useAuthentication } from '~/hooks/useAuthentication';

/** The user states that a user progresses through with use. */
export enum UserPortfolioState {
    'loading',
    'preDeposit', // First Time User
    'pendingDeposit', // Deposit Pending
    'fundedPreWine', // Deposit settled and waiting for wine
    'fundedWithWine' // Populated
}

export const initialManagePortfolioContext: ManagePortfolioContextContent = {
    id: undefined,
    needsOnboarding: true,
    state: UserPortfolioState.loading,
    setUserState: (): void => {},
    refetchManagedContext: (): void => {}
};

export interface ManagePortfolioContextContent {
    id?: string;
    needsOnboarding: boolean;
    state: UserPortfolioState;
    refetchManagedContext: () => void;
    setUserState: Dispatch<SetStateAction<UserPortfolioState>>;
}

export const ManagedPortfolioContext = createContext(initialManagePortfolioContext);

ManagedPortfolioContext.displayName = 'ManagedPortfolioContext';

/**
 * Context holds the users managed portolio information.
 */
export const ManagedPortfolioProvider: React.FC<{ children?: ReactNode | undefined }> = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    const context = useContext(ManagedPortfolioContext);
    const { state } = context;
    const [value, setValue] = useState(context);
    const [userState, setUserState] = useState(state);

    const [authenticated, setAuthenticated] = useState(false);

    const {
        data: portfolioData,
        isSuccess: portfolioDataSuccess,
        isLoading: isPortfolioLoading,
        isError: isPortfolioError,
        refetch: refetchPortfolioOverview
    } = useManagedPortfolioQuery();

    const {
        data: pendingTransfers,
        isSuccess: pendingTransferSuccess,
        isLoading: isPendingTransfersLoading,
        refetch: refetchPendingTransfers
    } = usePendingPortfolioTransactions(portfolioData?.id);

    const refetchManagedContext = useCallback((): void => {
        refetchPortfolioOverview();
        refetchPendingTransfers();
    }, [refetchPendingTransfers, refetchPortfolioOverview]);

    useEffect(() => {
        if (!!portfolioData && !!pendingTransfers) {
            const { bottleCount, cash } = portfolioData;
            if (isPortfolioLoading || isPendingTransfersLoading) {
                setUserState(UserPortfolioState.loading);
            } else if (bottleCount !== undefined && bottleCount >= 1) {
                setUserState(UserPortfolioState.fundedWithWine);
            } else if (cash && bottleCount !== undefined && bottleCount === 0 && cash.amount >= 1) {
                setUserState(UserPortfolioState.fundedPreWine);
            } else if (pendingTransfers && pendingTransfers.length > 0 && bottleCount === 0) {
                setUserState(UserPortfolioState.pendingDeposit);
            } else {
                setUserState(UserPortfolioState.preDeposit);
            }
        }
    }, [
        pendingTransferSuccess,
        pendingTransfers,
        portfolioDataSuccess,
        isPortfolioLoading,
        isPendingTransfersLoading,
        portfolioData,
        isPortfolioError
    ]);

    useEffect(() => {
        (async () => {
            setAuthenticated(isAuthenticated);
        })();
        if (portfolioDataSuccess && !!portfolioData) {
            setValue({
                ...value,
                id: portfolioData?.id,
                state: userState,
                needsOnboarding: !(portfolioData as any)?.id
            } as any);
        }
    }, [authenticated, portfolioDataSuccess, refetchManagedContext, userState, portfolioData]);

    return <ManagedPortfolioContext.Provider value={value}>{children}</ManagedPortfolioContext.Provider>;
};
