import { MakeMangedRequestOptions } from '~/networking/client';
import { Response, httpRequest } from '~/networking/utils';
import { GetUserAutoPayStatusResponse } from '../../responses/users/GetUserAutoPayStatusResponse';

export const getUserAutoPayStatus =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetUserAutoPayStatusResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/autopay/status`);

        return httpRequest.get(httpRequestUrl, { headers });
    };
