export const primitives = {
    platinum: {
        800: '#242E35',
        700: '#2C394',
        600: '#415B68',
        500: '#517082',
        400: '#94B6C8',
        300: '#ABCADA',
        200: '#C7DFEC',
        100: '#D3E7F2'
    },
    blue: {
        800: '#112D47',
        700: '#1C4366',
        600: '#4F81B0',
        500: '#879EB4',
        400: '#C5D5E4',
        300: '#D0E1F1',
        200: '#E0F0FF',
        100: '#EFF7FF'
    },
    turtleGreen: {
        800: '#3C400C',
        700: '#525716',
        600: '#82972D',
        500: '#9CA591',
        400: '#E0E5CD',
        300: '#F1F6DB',
        200: '#FAFFE3',
        100: '#FDFFF2'
    },
    violet: {
        800: '#3C400C',
        700: '#74293B',
        600: '#A4465D',
        500: '#B48785',
        400: '#E6C9C9',
        300: '#FDE1E1',
        200: '#FFEEEE',
        100: '#FFF5F'
    },
    brown: {
        800: '#513011',
        700: '#85572D',
        600: '#A86D37',
        500: '#B49685',
        400: '#EFDDC7',
        300: '#FAE8D1',
        200: '#FFF4E8',
        100: '#FFF8F1'
    },
    yellow: {
        800: '#674D1B',
        700: '#997328',
        600: '#FFC043',
        500: '#FFCF70',
        400: '#FFE3AC',
        300: '#FFEAC1',
        200: '#FFF2D9',
        100: '#FFFAF0'
    },
    green: {
        800: '#0D4910',
        700: '#1B711F',
        600: '378638', // '#51AC55'
        500: '#66C66A',
        400: '#7EDF83',
        300: '#C2EEC5',
        200: '#242E36',
        100: '#EBFFEC'
    },
    red: {
        800: '#5A0A00',
        700: '#870F00',
        600: '#DB4A38',
        500: '#EE8577',
        400: '#F1998E',
        300: '#FED7D2',
        200: '#FFEFED',
        100: '#FFF9F9'
    },
    white: '#FFFFFF',
    black: '#000000',
    gray: {
        light: '#eeeeee',
        800: '#000000',
        700: '#202020',
        600: '#404040',
        500: '#606060',
        400: '#B5B5B5',
        300: '#D8D8D8',
        200: '#F6F6F6',
        100: '#FFFFFF'
    },
    neutrals: {
        900: '#242E35',
        800: '#303941',
        700: '#384147',
        600: '#5B646B',
        500: '#767A7F',
        400: '#A8ABAD',
        300: '#CACCCE',
        200: '#EEEEEE',
        100: '#FAFAFA'
    }
};
