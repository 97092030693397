import { GetAccountDocumentLinkResponse } from '~/networking/api/managed/responses';
import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const getAccountDocumentLink = ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) => {
    return async (id: string): Promise<Response<GetAccountDocumentLinkResponse>> => {
        const headers = getHeaders(true);
        const endpoint = getRequestUrl(`/v2/documents/${getPortfolioId()}/${id}`);
        return httpRequest.get<GetAccountDocumentLinkResponse>(endpoint, { headers });
    };
};
