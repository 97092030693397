import React, { FC, type ReactNode } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import { ZendeskScript } from '~/components/global/document/head/ZendeskScript';
import { GlobalEventListeners } from '~/components/global/GlobalEventListeners';
import { DepositProvider } from '~/context/Deposit/DepositContext';
import { ManagedPortfolioProvider } from '~/context/ManagedPortfolioContext';
import { ModalRouterContextProvider } from '~/context/ModalRouterContext';
import { TradingPortfolioProvider } from '~/context/TradingPortfolioContext';
import { NewRegistrationTracking } from '~/helpers/NewRegistrationTracking';
import { ServerUAContext, ServerUAProps } from '~/hooks/useWindowSize';
import GlobalStyles from '~/styles/GlobalStyles';
import { theme } from '~/styles/theme';
import dynamic from 'next/dynamic';

const DepositTransferModal = dynamic(import('~/components/modules/Deposit/DepositTransferModal'), { ssr: false });
const ConnectSourceModal = dynamic(import('~/components/modules/Deposit/ConnectSourceModal'), { ssr: false });

const ProviderContainer: FC<{
    children?: ReactNode | undefined;
    dehydratedState: unknown;
    queryClient: QueryClient;
    serverDevice: ServerUAProps;
}> = ({ children, queryClient, dehydratedState, serverDevice }) => {
    return (
        <>
            <ServerUAContext.Provider value={serverDevice}>
                <QueryClientProvider client={queryClient}>
                    <Hydrate state={dehydratedState}>
                        <ManagedPortfolioProvider>
                            <TradingPortfolioProvider>
                                <DepositProvider>
                                    <ModalRouterContextProvider>
                                        <ThemeProvider theme={theme}>
                                            <GlobalEventListeners queryClient={queryClient} />
                                            <GlobalStyles />
                                            {children}
                                            <ConnectSourceModal />
                                            <DepositTransferModal />
                                            <NewRegistrationTracking />
                                            <ZendeskScript />
                                        </ThemeProvider>
                                    </ModalRouterContextProvider>
                                </DepositProvider>
                            </TradingPortfolioProvider>
                        </ManagedPortfolioProvider>
                    </Hydrate>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </ServerUAContext.Provider>
        </>
    );
};

export default ProviderContainer;
