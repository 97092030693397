/**
 * Fees used to modify a user's portfolio. All supported fees can be found
 * in the backend source -
 * https://github.com/Vinovest/backend/blob/master/pkg/managers/fees/fees.go#L39
 */

export enum FeeTypes {
    EarlyLiquidationFee = 'early_liquidation_fee',
    FeeAdjustment = 'fee_adjustment',
    FundsAdded = 'funds_added',
    FundsWithdrew = 'funds_withdrew',
    FundsFailed = 'funds_failed',
    TransferIn = 'transfer_in',
    TradeSettlementPurchase = 'trade_settlement_purchase',
    WinePurchase = 'wine_purchase',
    WineSold = 'wine_sold',
    WineSold3rdParty = 'wine_sold_third_party',
    VinovestMonthly = 'vinovest_monthly_fee',
    VinovestMonthlyWaived = 'vinovest_monthly_fee_waved',
    RebalancedAdd = 'rebalance_add',
    EarlyWithdrawSixtyDay = 'early_withdrawl_60_day',
    WineVerification = 'wine_verification',
    WineShipped = 'wine_shipped',
    PendingWinePurchase = 'funds_auth_hold',
    MonthlyStorageFee = 'monthly_storage_fee',
    ReferralRewardCredit = 'referral_reward_credit',
    ReferralRewardDebit = 'referral_reward_debit',
    WinePurchaseAdjustment = 'wine_purchase_adjustment',
    WinePurchaseAdjustmentDebit = 'wine_purchase_adjustment_debit',
    WineSoldAdjustment = 'wine_sold_adjustment',
    WineSoldAdjustmentDebit = 'wine_sold_adjustment_debit',
    LateFee = 'late_fee'
}
