import { GoogleTagManager } from '@next/third-parties/google'


export const GoogleTagManagerScript = () => {
    if (process.env.NODE_ENV !== 'production') {
        return null;
    }

    return <GoogleTagManager gtmId="GTM-5Q6VLCP"
                             auth={process.env.NEXT_PUBLIC_GTM_AUTH}
                             preview={process.env.NEXT_PUBLIC_GTM_PREVIEW}
                             dataLayerName={'dataLayer'} />;
};
