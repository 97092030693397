import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const postBulkAutolistWines = ({ getHeaders, getPortfolioId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (useRange = false): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const url = getRequestUrl(`/v1/offer/portfolio/${getPortfolioId()}/autolist`);

        const result = await httpRequest.post(url, {
            headers,
            body: JSON.stringify({ useRange })
        });
        return result;
    };
};
