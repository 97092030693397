import { GetCollectionResponse } from '~/networking/api/trading/responses/collection/ResponseGetCollection';
import { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getCollectionById =
    ({ getHeaders, getRequestUrl }: MakeTradingRequestOptions) =>
    async (collectionId: string): Promise<Response<GetCollectionResponse>> => {
        const headers = getHeaders(true);
        const getCollectionEndpoint = getRequestUrl(`/v1/collection/${collectionId}`);

        return httpRequest.get(getCollectionEndpoint, { headers });
    };
