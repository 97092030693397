import { useMemo } from 'react';
import { useAuthentication } from '../useAuthentication';
import { useTradingPortfolioQuery } from './portfolios/trading/useTradingPortfolioQuery';
import {
    PortfolioInformation,
    transformPortfoliosToKeys,
    useGetUserPortfolios
} from './portfolios/useGetUserPortfolios';

// TODO: add the errors and stuff to the return object too
export const useGetUserPortfoliosInfo = () => {
    const { isAuthenticated, userId } = useAuthentication();
    const { data: userPortfolios, isSuccess } = useGetUserPortfolios();
    // Since trading is NOT in portfolios we have to query it separately
    const {
        data: trading,
        isLoading: tradingLoading,
        isSuccess: isTradingSucess,
        error: tradingError
    } = useTradingPortfolioQuery();

    const portfolios = useMemo(() => {
        return userPortfolios?.portfolios != null
            ? transformPortfoliosToKeys(userPortfolios.portfolios)
            : // TODO: can we just return an empy object? this seems sus
              ({ whiskey: { id: '' } } as unknown as PortfolioInformation);
    }, [userId, isAuthenticated, isSuccess, userPortfolios]);

    return useMemo(
        () => ({
            whiskey: portfolios.whiskey,
            managed: portfolios.managed,
            trading,
            userPortfolios,
            isWhiskeySuccess: isSuccess,
            isManagedSucces: isSuccess,
            isTradingSucess,
            tradingError,
            tradingLoading
        }),
        [portfolios, userPortfolios, isSuccess, trading, tradingLoading, isTradingSucess, tradingError]
    );
};
