import { ValueOf } from '~/networking/models/ValueOf';

export enum InvestingStyleAnswers {
    Conservative = 'Conservative',
    Moderate = 'Moderate',
    Aggressive = 'Aggressive'
}

export enum GoalsWithVinovestAnswers {
    HigherReturns = 'Earn higher returns than what I am currently getting from my other investments',
    Diversification = 'Diversify into alternative asset classes',
    Passion = 'Invest in something that I am passionate about'
}

export enum AnticipatedCashAccessAnswers {
    ThreeYears = 'Less than 3 years',
    ThreeToSevenYears = 'Between 3 and 7 years',
    MoreTHanSevenYears = 'More than 7 years'
}

export enum CashAnswers {
    Yes = 'Yes',
    No = 'No'
}

export type NewUserQuizAnswerTypes = GoalsWithVinovestAnswers | InvestingStyleAnswers | AnticipatedCashAccessAnswers;

export interface NewUserQuiz {
    investingStyle: ValueOf<InvestingStyleAnswers>;
    goalsWithVinovest: ValueOf<GoalsWithVinovestAnswers>;
    anticipatedCashAccess: ValueOf<AnticipatedCashAccessAnswers>;
    phoneNumber: string;
    cash: ValueOf<CashAnswers>;
}
