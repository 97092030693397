import { GetAccountStatementsResponse } from '~/networking/api';
import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export interface GetAccountStatmentsParams {
    year?: string;
    pageSize?: number;
    pageToken?: string;
}

export const getAccountStatements = ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) => {
    return async (params?: GetAccountStatmentsParams): Promise<Response<GetAccountStatementsResponse>> => {
        const headers = getHeaders(true);
        const urlParams = new URLSearchParams({
            year: params?.year || '',
            pageSize: String(params?.pageSize || 10),
            pageToken: params?.pageToken || ''
        });
        const endpoint = getRequestUrl(`/v2/documents/${getPortfolioId()}/monthly?${urlParams.toString()}`);
        return httpRequest.get<GetAccountStatementsResponse>(endpoint, { headers });
    };
};
