import { CreateBitpayInvoiceResponse } from '~/networking/api/managed/responses/payments/CreateBitpayInvoiceResponse';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

interface BitpayInvoicePayload {
    amount: {
        amount: number;
        currency: string;
    };
    redirect: string;
    portfolioID: string;
}

export const createBitpayInvoice =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (bitpayInvoice: BitpayInvoicePayload): Promise<Response<CreateBitpayInvoiceResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(
            `/v1/payment/${getUserId()}/portfolio/${bitpayInvoice.portfolioID}/bitpay/invoice`
        );

        return httpRequest.post(httpRequestUrl, {
            headers,
            body: bitpayInvoice
        });
    };
