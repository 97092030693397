import { GetActiveBidsResponse } from '~/networking/api/managed/responses/portfolio/GetActiveBidsResponse';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getActiveBids =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetActiveBidsResponse>> => {
        const headers = getHeaders(true);
        const getActiveBidsEndpoint = getRequestUrl(`/v1/portfolio/${getPortfolioId()}/bids/active`);

        return httpRequest.get(getActiveBidsEndpoint, { headers });
    };
