import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';

import { ErrorMessage, ErrorMessageProps } from '~/components/modules/Errors/ErrorMessage';

import { errorEventListener } from './errorEventListener';

export const ErrorContainer: React.FC<Record<string, never>> = () => {
    const [errors, setErrors] = useState([] as ErrorMessageProps[]);

    const handleCloseMessage = () => {
        setErrors([]);
    };

    useEffect(() => {
        return errorEventListener(({ detail }) => {
            const errorOptions = {
                showError: true,
                id: errors.length,
                handleClose: handleCloseMessage,
                errorMessage: detail.errorMessage
            };
            setErrors([errorOptions]);
        });
    });

    /**
     * These top and left values need to be dynamic or set as baseline values
     * if we want to implement an error queue in the future.
     *  */
    const portalElement = typeof window === 'undefined' ? undefined : document.querySelector('#portal');

    return (
        <div className={'top-[4px] left-[16px] absolute z-50 w-3/4'}>
            {portalElement
                ? ReactDOM.createPortal(
                      <>
                          {errors.map((errorProps) => {
                              return (
                                  <div className={'w-3/4'} key={uuid()}>
                                      <ErrorMessage {...errorProps} />
                                  </div>
                              );
                          })}
                      </>,
                      portalElement
                  )
                : null}
        </div>
    );
};
