import React, { createContext, type ReactNode, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { useCreateTradingPortfolioMutation } from '~/hooks/mutations/trading-portfolio/useCreateTradingPortfolioMutation';
import { useTradingPortfolioQuery } from '~/hooks/queries/portfolios/trading/useTradingPortfolioQuery';
import { useAuthentication } from '~/hooks/useAuthentication';

export const initialTradingPortfolioContext: TradingPortfolioContextContent = {
    id: undefined,
    needsOnboarding: true
};

export interface TradingPortfolioContextContent {
    id?: string;
    needsOnboarding: boolean;
}

export const TradingPortfolioContext = createContext(initialTradingPortfolioContext);

TradingPortfolioContext.displayName = 'TradingPortfolioContext';

/**
 * Context holds the users trading portfolio id and onboarding flag.
 * Performs a checks if the authenticated user has a trading portfolio id,
 * if not one will be created for them.
 */
export const TradingPortfolioProvider: React.FC<{ children?: ReactNode | undefined }> = (props) => {
    const { isAuthenticated, authStore } = useAuthentication();
    const context = useContext(TradingPortfolioContext);
    const [value, setValue] = useState(context);
    const { data: tradingPortfolio, mutateAsync } = useCreateTradingPortfolioMutation();
    const { data: trading } = useTradingPortfolioQuery();
    const client = useQueryClient();

    const updateTradingPortfolio = async (tradingId: () => string) => {
        authStore.setTradingPortfolioId(tradingId());
        await Promise.all([
            client.invalidateQueries(queries.getTradingPortfolio),
            client.invalidateQueries(queries.getTradingOrders),
            client.invalidateQueries(queries.getTradingPendingCharges),
            client.invalidateQueries(queries.getTradingPortfolioBottles)
        ]);

        if (trading) {
            const needsOnboarding = trading?.needsOnboarding;
            setValue((prevState) => ({ ...prevState, needsOnboarding }));
        }
    };

    useEffect(() => {
        if (isAuthenticated && !tradingPortfolio?.id) {
            mutateAsync();
        }

        if (tradingPortfolio?.id) {
            setValue((prevState) => ({
                ...prevState,
                id: tradingPortfolio?.id
            }));
            updateTradingPortfolio(() => tradingPortfolio.id);
        }
    }, [isAuthenticated, mutateAsync, tradingPortfolio?.id, trading]);

    return <TradingPortfolioContext.Provider value={value}>{props.children}</TradingPortfolioContext.Provider>;
};
