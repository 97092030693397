import type { OfferReservation } from '~/networking/api/managed/responses/offers/upsellGroup';
import type { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest } from '~/networking/utils/httpRequest';

export interface CancelReserveProductInOfferGroupPayload {
    portfolioID: string;
    reservationIDs: string[];
}

export const cancelReservedOffers =
    ({ getHeaders, getRequestUrl }: MakeMangedRequestOptions) =>
    async (payload: CancelReserveProductInOfferGroupPayload) => {
        const headers = getHeaders(true);
        const reserveEndpoint = getRequestUrl(`/v1/offer/portfolio/${payload.portfolioID}/reserved`);

        const response = await httpRequest.delete<OfferReservation[]>(reserveEndpoint, {
            headers,
            body: payload.reservationIDs
        });
        if (!response.ok) {
            console.error('Failed to reserve upsell offer group %O', response);
        }

        return {
            ...response,
            data: null
        };
    };
