import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const putUserAutoPayCancel = ({ getHeaders, getUserId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/autopay/cancel`);

        const result = await httpRequest.put(httpRequestUrl, { headers });
        return result;
    };
};
