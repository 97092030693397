import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

import { GetOfferTotals } from '../../responses/offers/GetOfferTotalsResponse';

export const getOfferTotals =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetOfferTotals>> => {
        const headers = getHeaders(true);
        const getOfferTotalsEndpoint = getRequestUrl(`/v1/offer/portfolio/${getPortfolioId()}/totals`);

        return httpRequest.get(getOfferTotalsEndpoint, { headers });
    };
