import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export interface PutUserAutoPayUpdatePayload {
    payment_method_id: string;
}

export const putUserAutoPayUpdate = ({ getHeaders, getUserId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (updater: PutUserAutoPayUpdatePayload): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/autopay/update`);

        const result = await httpRequest.put(httpRequestUrl, { headers, body: JSON.stringify(updater) });
        return result;
    };
};
