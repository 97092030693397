import { QueryClient, useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { dispatchError } from '~/containers/ErrorContainer/errorEventListener';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import {
    VinovestNetworkHelperAuthenticationParams,
    VinovestNetworkingHelper
} from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';
import { GetUserPortfolioResponse, PortfolioPerformanceRanges } from '~/networking';

export const ManagedPortfolioQuery = {
    load: async (
        authenticationParams: VinovestNetworkHelperAuthenticationParams,
        selectedRange: PortfolioPerformanceRanges
    ): Promise<(GetUserPortfolioResponse & { code?: number; message?: string }) | null> => {
        const { userId } = authenticationParams;
        const { managed } = VinovestNetworkingHelper.getClient(authenticationParams);
        try {
            const userPortfolio = await managed.getUserPortfolio(selectedRange);
            if (!userPortfolio.data) {
                dispatchError('No portfolio found');
                return null;
            }
            return userPortfolio.data;
        } catch (error) {
            SentryHelper.captureException(error, {
                extra: { userId }
            });
            dispatchError(error);
            return null;
        }
    },
    queryKey: (userId: string, selectedRange: PortfolioPerformanceRanges) => [
        queries.getManagedPortfolio,
        userId,
        selectedRange
    ],
    prefetch: async (
        queryClient: QueryClient,
        authenticationParams: VinovestNetworkHelperAuthenticationParams,
        selectedRange: PortfolioPerformanceRanges
    ) => {
        const { userId } = authenticationParams;
        const queryKey = ManagedPortfolioQuery.queryKey(userId || '', selectedRange);
        await queryClient.prefetchQuery(queryKey, async () =>
            ManagedPortfolioQuery.load(authenticationParams, selectedRange)
        );
    }
};

/** Query for fetching users Managed Portfolio */
export const useManagedPortfolioQuery = (selectedRange = PortfolioPerformanceRanges.SixMonths) => {
    const { isAuthenticated, userId } = useAuthentication();

    return useQuery(
        ManagedPortfolioQuery.queryKey(userId as string, selectedRange),
        async (): Promise<(GetUserPortfolioResponse & { code?: number; message?: string }) | null> =>
            ManagedPortfolioQuery.load({ userId }, selectedRange),
        {
            enabled: Boolean(isAuthenticated && userId),
            staleTime: 1000 * 60
        }
    );
};
