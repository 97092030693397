import { GetUserWinesResponse } from '~/networking/api/managed';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getUserWines =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (pageSize?: number, pageToken?: string, portfolioID?: string): Promise<Response<GetUserWinesResponse>> => {
        const pageTokenParameter = pageToken && pageSize ? `?pageSize=${pageSize}&pageToken=${pageToken}` : '';
        const useablePortfolioID = portfolioID || getPortfolioId();
        const headers = getHeaders(true);
        const getUserWinesEndpoint = getRequestUrl(`/v1/portfolio/${useablePortfolioID}/wines${pageTokenParameter}`);

        return httpRequest.get(getUserWinesEndpoint, { headers });
    };
