import type { OfferReservation } from '~/networking/api/managed/responses/offers/upsellGroup';
import type { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest } from '~/networking/utils/httpRequest';

export interface ReserveProductInOfferGroupPayload {
    portfolioId: string;
    groupId: string;
    count: number;
}

export const reserveUpsellOfferGroup =
    ({ getHeaders, getRequestUrl }: MakeMangedRequestOptions) =>
    async (payload: ReserveProductInOfferGroupPayload) => {
        const headers = getHeaders(true);
        const reserveEndpoint = getRequestUrl(
            `/v1/offer/portfolio/${payload.portfolioId}/group/${payload.groupId}/reserve`
        );

        const response = await httpRequest.post<OfferReservation[]>(reserveEndpoint, { headers, body: payload });
        const data = response.data || [];
        if (!response.ok || !Array.isArray(data)) {
            console.error('Failed to reserve upsell offer group %O', response);
            throw new Error('Failed to reserve upsell offer group');
        }

        return {
            ...response,
            data: data.map((r) => {
                // eslint-disable-next-line no-param-reassign
                r.expiresAt = new Date(r.expiresAt);
                return r;
            })
        };
    };
