import { CreateStripeSecretKeyResponse } from '~/networking/api/managed/responses/payments/CreateStripeSecretKeyResponse';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const createStripeSecretKey =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (portfolioID: string): Promise<Response<CreateStripeSecretKeyResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/payment/${getUserId()}/portfolio/${portfolioID}/intent/card`);

        return httpRequest.post(httpRequestUrl, {
            headers
        });
    };
