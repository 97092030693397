import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const getPortfolioAssetsAsCsv =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (portfolioID: string | undefined): Promise<Response<string>> => {
        const useablePortfolioId = portfolioID || getPortfolioId();
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/portfolio/${useablePortfolioId}/wines/csv`);

        const result = await httpRequest.get<string>(httpRequestUrl, { headers });
        return result;
    };
