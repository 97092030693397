import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const cancelBulkLiquidateWines = ({ getHeaders, getPortfolioId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const cancelBulkLiquidate = getRequestUrl(`/v1/portfolio/${getPortfolioId()}/liquidate`);

        const result = await httpRequest.delete(cancelBulkLiquidate, { headers });
        return result;
    };
};
