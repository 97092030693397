import { GetPortfolioFeesResponse } from '~/networking/api/managed';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { FeeTypes } from '~/networking/models';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export interface GetPortfolioFeesRequest {
    pageToken?: string;
    pageSize?: number;
    feeType?: FeeTypes;
}

export const getPortfolioFees =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (params?: GetPortfolioFeesRequest, portfolioID?: string): Promise<Response<GetPortfolioFeesResponse>> => {
        const headers = getHeaders(true);
        const useablePortfolioID = portfolioID || getPortfolioId();
        const queryParams = new URLSearchParams({
            pageToken: params?.pageToken || '',
            pageSize: String(params?.pageSize || ''),
            type: String(params?.feeType || '')
        });
        const getPortfolioFeesEndpoint = getRequestUrl(
            `/v2/portfolio/${useablePortfolioID}/fees?${queryParams.toString()}`
        );

        return httpRequest.get(getPortfolioFeesEndpoint, { headers });
    };
