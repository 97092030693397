declare global {
    interface Window {
        zESettings: any;
    }
}

const zendeskPagesMap: Record<string, number> = {
    '/help': 1,
    '/help-category': 1
};

export const ZendeskHelper = {
    /** Sets the global settings for the zendesk widget */
    setZendeskSettings() {
        if (typeof window !== 'undefined') {
            window.zESettings = {
                webWidget: {
                    color: { theme: '#4f1c28' }
                }
            };
        }
    },
    isZendeskPage(pathName: string) {
        const isRoot = !pathName.includes('/', 1);
        const rootPath = isRoot ? pathName : pathName.slice(0, pathName.indexOf('/', 1));
        return !!zendeskPagesMap[rootPath];
    }
};
