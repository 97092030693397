import type { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest } from '~/networking/utils/httpRequest';

export interface PurchaseReservedOffersPayload {
    portfolioID: string;
    reservationIDs: string[];
}

export interface PurchaseReservedOffersResponse {
    tradeIDs: string[];
    errors: string[];
}

export const purchaseReservedOffers =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (payload: PurchaseReservedOffersPayload) => {
        const headers = getHeaders(true);
        const reserveEndpoint = getRequestUrl(
            `/v1/payment/${getUserId()}/portfolio/${payload.portfolioID}/reservations`
        );

        const response = await httpRequest.post<PurchaseReservedOffersResponse>(reserveEndpoint, {
            headers,
            body: { reservationIDs: payload.reservationIDs }
        });
        if (!response.ok) {
            console.error('Failed to reserve upsell offer group %O', response);
        }

        return response;
    };
