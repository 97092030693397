export type InvestmentStrategyKey = keyof typeof InvestmentStrategy;

/** Investment strategy used to allocate wine in a user's portfolio */
export enum InvestmentStrategy {
    conservative = 'Conservative',
    moderate = 'Moderate',
    aggressive = 'Aggressive'
}

/** Descriptions of strategies used by vinovest */
export const investmentStrategies = {
    conservative: {
        title: InvestmentStrategy.conservative,
        benefits: [
            'Are looking for a fine wine portfolio optimizing for downside protection',
            'Have a medium to long-term timeline',
            'Want a globally diversified portfolio of wines with a proven historical track record'
        ]
    },
    moderate: {
        title: InvestmentStrategy.moderate,
        benefits: [
            'Are looking for a fine wine portfolio with maximum diversification',
            'Have a medium to long-term timeline',
            'May be newer to investing outside the stock market'
        ]
    },
    aggressive: {
        title: InvestmentStrategy.aggressive,
        benefits: [
            'Are looking for a fine wine portfolio with target allocation toward high growth wine regions',
            'Have a medium to long-term timeline',
            'Have had experience investing another alternative asset classes'
        ]
    }
};
