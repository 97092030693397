import React, { createContext, type ReactNode, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, type Stripe } from '@stripe/stripe-js';

import { useDepositDestinationValues } from '~/context/Deposit/useChangeDepositDestinationValues';
import { DepositFrequency } from '~/models/deposits/DepositFrequency';
import { PaymentMethod } from '~/models/PaymentMethod';
import { PortfolioTypes } from '~/models/PortfolioType';
import { GetUserPaymentMethodsResponse } from '~/networking';

export type AllocationClassification = 'lots' | 'casks' | 'scotch';

export interface DepositContextProps {
    depositAmount: number;
    destinationPortfolioId: string;
    destinationPortfolio: PortfolioTypes;
    depositFrequency: DepositFrequency;
    paymentMethod: PaymentMethod;
    defaultPaymentSourceID: string;
    minimumDepositAmount: number;
    allocationClassification: AllocationClassification | null;
    setDepositAmount: (deposit: number) => void;
    setDestinationPortfolio: (destination: PortfolioTypes) => void;
    setPaymentMethod: (paymentMethod: any) => void;
    setDepositFrequency: (frequency: DepositFrequency) => void;
    setDefaultPaymentSourceID: (id: string) => void;
    setMinDepositAmount: (amount: number) => void;
    setAllocationClassification: (alloClass: AllocationClassification | null) => void;
    refetchPaymentMethods: () => void;
    savedPaymentMethods?: GetUserPaymentMethodsResponse['paymentMethods'];
    paymentMethodsLoaded: boolean;
}

export const depositDefaultState: DepositContextProps = {
    destinationPortfolioId: '',
    depositAmount: 0,
    destinationPortfolio: PortfolioTypes.managed,
    paymentMethod: {
        id: '',
        type: '',
        name: '',
        lastFour: '',
        accountID: '',
        ach: {
            name: '',
            lastFour: ''
        },
        card: {
            brand: '',
            lastFour: '',
            countryCode: ''
        },
        bank: {
            name: '',
            lastFour: ''
        }
    },
    depositFrequency: DepositFrequency.once,
    defaultPaymentSourceID: 'new',
    minimumDepositAmount: 1000,
    allocationClassification: null,
    setDepositFrequency: () => {},
    setDepositAmount: () => {},
    setDestinationPortfolio: () => {},
    setPaymentMethod: () => {},
    setDefaultPaymentSourceID: () => {},
    setMinDepositAmount: () => {},
    setAllocationClassification: () => {},
    refetchPaymentMethods: () => {},
    paymentMethodsLoaded: false
};

let stripeClient: Promise<Stripe | null> | null = null;
export const DepositContext = createContext<DepositContextProps>(depositDefaultState);

/** Deposit wrapper to pass the stripe wrapper and share context across all deposit screens. */
export const DepositProvider: React.FC<{ children?: ReactNode | undefined }> = ({ children }) => {
    const [depositAmount, setDepositAmount] = useState(depositDefaultState.depositAmount);
    const [destinationPortfolio, setDestinationPortfolio] = useState<PortfolioTypes>(
        depositDefaultState.destinationPortfolio
    );
    const [minimumDepositAmount, setMinDepositAmount] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = useState(depositDefaultState.paymentMethod);
    const [depositFrequency, setDepositFrequency] = useState<DepositFrequency>(depositDefaultState.depositFrequency);
    const [defaultPaymentSourceID, setDefaultPaymentSourceID] = useState(depositDefaultState.defaultPaymentSourceID);
    const [allocationClassification, setAllocationClassification] = useState<AllocationClassification | null>(null);

    const { destinationPortfolioId, refetchPaymentMethods, savedPaymentMethods, paymentMethodsLoaded } =
        useDepositDestinationValues(destinationPortfolio);

    useEffect(() => {
        if (savedPaymentMethods.length === 0) {
            setPaymentMethod(depositDefaultState.paymentMethod);
            setDefaultPaymentSourceID(depositDefaultState.defaultPaymentSourceID);
        }
    }, [destinationPortfolio]);

    if (!stripeClient) {
        stripeClient = loadStripe(process.env.NEXT_PUBLIC_STRIPE_CLIENT_TOKEN);
    }

    return (
        <Elements stripe={stripeClient}>
            <DepositContext.Provider
                value={{
                    destinationPortfolioId,
                    depositAmount,
                    setDepositAmount,
                    destinationPortfolio,
                    setDestinationPortfolio,
                    paymentMethod,
                    setPaymentMethod,
                    depositFrequency,
                    setDepositFrequency,
                    defaultPaymentSourceID,
                    setDefaultPaymentSourceID,
                    minimumDepositAmount,
                    setMinDepositAmount,
                    allocationClassification,
                    setAllocationClassification,
                    refetchPaymentMethods,
                    savedPaymentMethods,
                    paymentMethodsLoaded
                }}
            >
                <main>{children}</main>
            </DepositContext.Provider>
        </Elements>
    );
};
