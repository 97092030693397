import { SentryHelper } from './Sentry/SentryHelper';

/** Get item from storageMedium and parse it if you want. */
function buildGetItem(storageMedium: Storage) {
    return <K = string | undefined>(key: string, parse = false): K | null => {
        const storedToken = storageMedium.getItem(String(key));
        if (typeof storedToken === 'string') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return parse ? JSON.parse(storedToken) : storedToken;
        }
        return null;
    };
}

/** Set item in storageMedium */
function buildSetItem(storageMedium: Storage) {
    return (key: string, value: unknown) => {
        const valueToSet = typeof value === 'string' ? value : JSON.stringify(value);
        return storageMedium.setItem(String(key), valueToSet);
    };
}

/** Remove item from storageMedium */
function buildRemoveItem(storageMedium: Storage) {
    return (key: string) => {
        try {
            storageMedium.removeItem(key);
        } catch (error) {
            SentryHelper.captureException(error, { extra: { key } });
        }
    };
}

interface KeyStore {
    getItem: <K = string | undefined>(key: string, parse?: boolean) => K | null;
    setItem: (key: string, value: unknown) => void;
    removeItem: (key: string) => void;
}

const noopStorage: Storage = {
    getItem: (_: string) => {
        return null;
    },
    setItem: (_: string) => {},
    removeItem: (_: string) => {},
    length: 0,
    clear(): void {},
    key(_: number): string | null {
        return null;
    }
};

const safeLocalStorageAccess = () => {
    return typeof window !== 'undefined' ? localStorage : noopStorage;
};

const safeSessionStorageAccess = () => {
    return typeof window !== 'undefined' ? sessionStorage : noopStorage;
};

export const LocalStorageHelper: KeyStore = {
    getItem: buildGetItem(safeLocalStorageAccess()),
    setItem: buildSetItem(safeLocalStorageAccess()),
    removeItem: buildRemoveItem(safeLocalStorageAccess())
};

export const SessionStorageHelper: KeyStore = {
    getItem: buildGetItem(safeSessionStorageAccess()),
    setItem: buildSetItem(safeSessionStorageAccess()),
    removeItem: buildRemoveItem(safeSessionStorageAccess())
};
