import { useCallback, useContext, useEffect } from 'react';
import { QueryClient } from 'react-query';

import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { ApplicationEventNames } from '~/constants/ApplicationEvents';
import { queries } from '~/constants/queryKeys';
import { DepositContext } from '~/context/Deposit/DepositContext';
import { PortfolioTypes } from '~/models/PortfolioType';

type RefetchQueriesConfig = { [K in PortfolioTypes]: string[] };

const refetchQueries: RefetchQueriesConfig = {
    [PortfolioTypes.managed]: [
        queries.getPendingTransfers,
        queries.getManagedPortfolio,
        queries.getManagedPortfolioInfo,
        queries.getManagedPendingCharges,
        queries.getManagedUserProfile
    ],
    [PortfolioTypes.trading]: [
        queries.getPendingTransfers,
        queries.getTradingPortfolio,
        queries.getTradingTransactions,
        queries.getTradingPendingCharges
    ],
    [PortfolioTypes.whiskey]: [
        queries.getPendingTransfers,
        queries.getWhiskeyPortfolio,
        queries.getWhiskeyPortfolioInfo,
        queries.getWhiskeyPendingCharges,
        queries.getWhiskeyUserProfile
    ]
};

export const DepositEventListeners = ({ queryClient }: { queryClient: QueryClient }) => {
    const { destinationPortfolio } = useContext(DepositContext);

    const invalidatePending = useCallback(async () => {
        await Promise.all(
            refetchQueries[destinationPortfolio].map((queryKey) => {
                queryClient.invalidateQueries([queryKey]);
            })
        );
    }, [destinationPortfolio, queryClient]);

    const invalidatePaymentMethods = async () => queryClient.invalidateQueries([queries.getSavedPaymentMethods]);

    useEffect(() => {
        window.addEventListener(AnalyticsEventNames.PaymentMethodLinked, invalidatePaymentMethods);
        window.addEventListener(ApplicationEventNames.DepositSuccess, invalidatePending);
        window.addEventListener(ApplicationEventNames.DepositModalClosed, invalidatePending);
        return () => {
            window.removeEventListener(ApplicationEventNames.DepositModalClosed, invalidatePending);
            window.removeEventListener(AnalyticsEventNames.PaymentMethodLinked, invalidatePaymentMethods);
            window.removeEventListener(ApplicationEventNames.DepositSuccess, invalidatePending);
        };
    }, [destinationPortfolio]);

    return <>{null}</>;
};
