import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const postBulkLiquidateWines = ({ getHeaders, getPortfolioId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (immediate = false): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const url = getRequestUrl(`/v1/portfolio/${getPortfolioId()}/liquidate/initiate`);

        const result = await httpRequest.post(url, {
            headers,
            body: JSON.stringify({ immediate })
        });
        return result;
    };
};
