import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

import { GetPendingOfferListings } from '../../responses/portfolio/GetPendingOfferListings';

export const getPendingOfferListings =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetPendingOfferListings>> => {
        const headers = getHeaders(true);
        const getPendingOfferListingsEndpoint = getRequestUrl(
            `/v1/offer/portfolio/${getPortfolioId()}/pending/listings`
        );

        return httpRequest.get(getPendingOfferListingsEndpoint, { headers });
    };
