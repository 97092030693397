import { MakeMangedRequestOptions } from '~/networking/client';
import { Response, httpRequest } from '~/networking/utils';
import { GetUserAllocationPreferencesResponse } from '../../responses/users/GetUserAllocationPreferencesResponse';

export const getUserAllocationPreferences =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetUserAllocationPreferencesResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/allocationPreferences`);

        return httpRequest.get(httpRequestUrl, { headers });
    };
