import { ApiTypes } from './VinovestNetworkingClient';

/**
 * Options for the header factory.
 */
interface HeaderFactoryOptions {
    type: ApiTypes;
    secure?: boolean;
    getAccessToken?: () => string | undefined;
    getCurrencyCode?: () => string;
    headers?: { [key: string]: string } | Headers;
}

/**
 * Default header base to work from for the header factory
 * @param additionalHeaders - Additional headers to add to the base, default headers.
 */
export const makeHeaders = (additionalHeaders?: { [key: string]: string } | Headers | undefined) => ({
    accept: 'application/json',
    ...additionalHeaders
});

/**
 * Header generation based on authorization, accessToken or strapiToken and type. Allows for additional headers to be added.
 * @param headerFactoryOptions - Options for generating headers
 * - secure: boolean (todo should be named `include_credentials`)
 * - getAccessToken: () => string;
 * - strapiToken: string;
 * - type:     'strapi' | 'managed' | 'trading'
 * - headers: { [key: string]: string | {[key: string]: string} }
 */
export const headerFactory = ({
    secure,
    getAccessToken,
    getCurrencyCode,
    type,
    headers
}: HeaderFactoryOptions): { [key: string]: string } | Headers => {
    const selectedCurrencyHeader = { 'X-Currency': getCurrencyCode ? getCurrencyCode() : 'USD' };

    if (secure) {
        if (type === ApiTypes.CMS) {
            return makeHeaders({ Connection: 'keep-alive' });
        }

        if (type === ApiTypes.Managed || type === ApiTypes.Trading) {
            const token = getAccessToken?.();
            const auth = token ? { Authorization: `Bearer ${token}` } : ({} as unknown as undefined);
            return makeHeaders({ ...auth, ...headers, ...selectedCurrencyHeader });
        }
    }
    return makeHeaders(selectedCurrencyHeader);
};
