import { GetMarketValueResponse } from '~/networking/api/trading/responses/market/GetMarketValueResponse';
import { MakeTradingRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getMarketValue =
    ({ getHeaders, getRequestUrl }: MakeTradingRequestOptions) =>
    async (lwin16: string | string[]): Promise<Response<GetMarketValueResponse>> => {
        const headers = getHeaders(true);
        const getMarketValueEndpoint = getRequestUrl(`/v1/markets?lwin16s=${lwin16.toString()}`);

        return httpRequest.get(getMarketValueEndpoint, { headers });
    };
