import { createRoute } from 'next-typed-routes';

/**
 * Marketing routes on the web-client
 */
export enum MarketingRoutes {
    ContactUs = '/contact-us',
    Community = '/community',
    SignUp = '/signup',
    Help = '/help',
    WhyWine = 'why-wine',
    HowItWorks = '/how-it-works',
    Blog = '/blog',
    Trading = '/trading',
    Whiskeyvest = '/whiskeyvest'
}

/** Marketing navigation links for next router */
export const marketingRoutes = {
    help: createRoute(MarketingRoutes.Help),
    whyWine: createRoute(MarketingRoutes.WhyWine),
    howItWorks: createRoute(MarketingRoutes.HowItWorks),
    blog: createRoute(MarketingRoutes.Blog),
    trade: createRoute(MarketingRoutes.Trading),
    community: createRoute(MarketingRoutes.Community),
    whiskeyvest: createRoute(MarketingRoutes.Whiskeyvest)
};
