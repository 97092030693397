import { AnalyticsEventMap } from '~/constants/AnalyticsEventMap';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import { useGoogleTagManager } from '~/hooks/useGoogleTagManager';
import { throttle } from '~/utils/throttle';

type MetaDataCallback<T extends AnalyticsEventMap> = () => T[keyof T];
type MetaDataOptions<T extends AnalyticsEventMap> = T[keyof T] | MetaDataCallback<T>;
/** TrackEventOptions when used as a single prop */
export type TrackEventOptions<Event extends AnalyticsEventMap, Key extends keyof AnalyticsEventMap> = {
    eventName: Key;
    metaData?: MetaDataOptions<Event>;
    googleTagManager?: ReturnType<typeof useGoogleTagManager>['googleTagManager'];
    userID?: string;
};

/** Catch all for any allowed metadata */

type EventHandlerObject<T extends AnalyticsEventMap> = {
    [K in keyof T]: (eventHandlerEvent: KeyboardEvent | MouseEvent | TouchEvent) => MetaDataOptions<T> | void;
};

type EventHandlerCheck<T extends AnalyticsEventMap> = {
    [K in keyof EventHandlerCallbacks<T>]: boolean;
};

/** Generic event handler */
type EventHandler<T extends AnalyticsEventMap> = (
    eventHandlerEvent: KeyboardEvent | MouseEvent | TouchEvent
) => T[keyof T];

/** Event handlers that are accepted for injection */
interface EventHandlerCallbacks<T extends AnalyticsEventMap> {
    onClick?: EventHandler<T>;
    onKeyPress?: EventHandler<T>;
    onTouchEnd?: EventHandler<T>;
    onSubmit?: EventHandler<T>;
}

/** Process meta data as static or as a function */
export function handleMetaData<G>(meta?: G): G {
    if (!meta) {
        return {} as G;
    }
    if (typeof meta === 'function') {
        return meta() as G;
    }
    return meta;
}

export function toString<T extends AnalyticsEventMap>(key: keyof T): string {
    return String(key);
}

/** Generate new functions with posthog tracking functions called as well */
export function injectPosthogEvent<T extends AnalyticsEventMap, K extends keyof AnalyticsEventMap>(
    { eventName, metaData, googleTagManager, userID }: TrackEventOptions<T, K>,
    eventHandlers?: EventHandlerCallbacks<T>
) {
    if (eventHandlers) {
        return Object.entries(eventHandlers).reduce((callbacks, [functionName, functionToCall]) => {
            const injectedCallbacks = { ...callbacks };
            const newCallback = (eventHandlerEvent: KeyboardEvent | MouseEvent | TouchEvent) => {
                /** Keyboard event */
                if (eventHandlerEvent instanceof KeyboardEvent && eventHandlerEvent.key === 'Enter') {
                    const possibleMeta =
                        (functionToCall && functionToCall.apply(globalThis, eventHandlerEvent as any)) ??
                        ({} as MetaDataOptions<T>);
                    const windowEvent = new CustomEvent<{ meta: any }>(eventName.toString(), {
                        detail: { meta: metaData || possibleMeta },
                        cancelable: false,
                        bubbles: true
                    });
                    window.dispatchEvent(windowEvent);
                    PosthogHelper.captureEvent(eventName, handleMetaData<T>(metaData || possibleMeta) as any);
                    SentryHelper.addBreadCrumb({
                        event_id: String(eventName),
                        data: metaData || (possibleMeta as Record<string, unknown>)
                    });

                    return possibleMeta;
                }

                const possibleMeta = functionToCall && functionToCall.apply(globalThis, eventHandlerEvent as any);
                const cleanedMetaData = handleMetaData(metaData || possibleMeta);

                /** Would use a single analytics client here in place of posthog the future and handle distribution within it */
                PosthogHelper.captureEvent(eventName, cleanedMetaData as any);
                /** Dispatch event in google tag manager */
                googleTagManager &&
                    googleTagManager[String(eventName)] &&
                    googleTagManager[String(eventName)]({ userID, ...cleanedMetaData });

                SentryHelper.addBreadCrumb({
                    event_id: String(eventName),
                    data: cleanedMetaData
                });

                return possibleMeta;
            };

            /** throttle the event firing to once every 300ms */
            (injectedCallbacks as any)[functionName] = throttle(newCallback, 300, callbacks);
            return injectedCallbacks;
        }, {} as EventHandlerObject<T>);
    }
    return {};
}

/** Checks to see if the Element has an event handler already */
export function hasEventHandler<T extends AnalyticsEventMap>(callBacks: EventHandlerCallbacks<T>) {
    return Object.entries(callBacks).reduce((handlers, [handlerName, potentialFunction]) => {
        const newHandlers = { ...handlers };
        if (typeof potentialFunction === 'function') {
            (newHandlers as any)[handlerName] = true;
        }
        (newHandlers as any)[handlerName] = false;
        return newHandlers;
    }, {} as EventHandlerCheck<T>);
}
