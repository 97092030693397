import { GetStripeSubscriptionManagementUrl } from '~/networking/api/managed';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const createStripeSubscriptionManagementUrl =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (returnUrl: string, portfolioID: string): Promise<Response<GetStripeSubscriptionManagementUrl>> => {
        const headers = getHeaders(true);
        const createStripeSubscriptionManagementUrlEndpoint = getRequestUrl(
            `/v1/payment/${getUserId()}/portfolio/${portfolioID}/stripe/portal`
        );

        return httpRequest.post(createStripeSubscriptionManagementUrlEndpoint, {
            headers,
            body: JSON.stringify({
                returnUrl
            })
        });
    };
