import type { GetReservationsInGroup } from '~/networking';
import type { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest } from '~/networking/utils/httpRequest';

export const getReservationsInGroup =
    ({ getHeaders, getRequestUrl }: MakeMangedRequestOptions) =>
    async (groupId: string, portfolioId: string) => {
        const headers = getHeaders(true);
        const url = getRequestUrl(`/v1/offer/portfolio/${portfolioId}/group/${groupId}/reserved`);

        const response = await httpRequest.get<GetReservationsInGroup>(url, { headers });
        const data: GetReservationsInGroup = response.data || { reservations: [] };
        data.reservations = data.reservations.map((r) => {
            // eslint-disable-next-line no-param-reassign
            r.expiresAt = new Date(r.expiresAt);
            return r;
        });

        return {
            ...response,
            data
        };
    };
