import type { GetTradingPortfolioTransactionsResponse } from '~/networking/api/trading/responses';
import type { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getTradingPortfolioTransactions =
    ({ getHeaders, getRequestUrl, getTradingPortfolioId }: MakeTradingRequestOptions) =>
    async (): Promise<Response<GetTradingPortfolioTransactionsResponse>> => {
        const headers = getHeaders(true);
        const getTradingPortfolioTransactionsEndpoint = getRequestUrl(
            `/v1/portfolio/${getTradingPortfolioId()}/transactions`
        );

        return httpRequest.get(getTradingPortfolioTransactionsEndpoint, { headers });
    };
