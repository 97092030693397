import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

export interface ErrorMessageProps {
    showError: boolean;
    handleClose: (id: number) => void;
    errorMessage?: string;
    id: number;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ showError, handleClose, errorMessage, id }) => {
    const errorToDisplay = errorMessage || 'There was a problem loading your page';
    const [show, setShow] = useState(true);

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live={'assertive'}
                className={'pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-[99]'}
            >
                <div className={'flex w-full flex-col items-center space-y-4 sm:items-end'}>
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={show && showError}
                        as={Fragment}
                        enter={'transform ease-out duration-300 transition'}
                        enterFrom={'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'}
                        enterTo={'translate-y-0 opacity-100 sm:translate-x-0'}
                        leave={'transition ease-in duration-100'}
                        leaveFrom={'opacity-100'}
                        leaveTo={'opacity-0'}
                    >
                        <div
                            className={
                                'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-red-500 shadow-lg ring-1 ring-black ring-opacity-5'
                            }
                        >
                            <div className={'p-4'}>
                                <div className={'flex items-start'}>
                                    <div className={'flex-shrink-0'}>
                                        <XCircleIcon className={'h-6 w-6 text-red-600'} aria-hidden={'true'} />
                                    </div>
                                    <div className={'ml-3 w-0 flex-1 pt-0.5'}>
                                        <p className={'text-sm font-medium text-white'}>Error:</p>

                                        <p className={'mt-1 text-sm text-white text-14'}>{errorToDisplay}</p>
                                    </div>
                                    <div className={'ml-4 flex flex-shrink-0'}>
                                        <button
                                            type={'button'}
                                            className={
                                                'inline-flex rounded-md bg-red-500 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                            }
                                            onClick={() => {
                                                setShow(false);
                                            }}
                                        >
                                            <span className={'sr-only'}>Close</span>
                                            <XIcon
                                                className={'h-5 w-5 text-gray-150'}
                                                aria-hidden={'true'}
                                                onClick={() => handleClose(id)}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
};
