import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export interface PutUserInformationPayload {
    firstName?: string;
    lastName?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    primaryPhone?: string;
    email?: string;
    utm_source?: string;
    utm_campaign?: string;
    utm_term?: string;
}

export const putUserInformation = ({ getHeaders, getUserId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (updater: PutUserInformationPayload): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/information`);

        const result = await httpRequest.put(httpRequestUrl, { headers, body: JSON.stringify(updater) });
        return result;
    };
};
