/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import { handleMetaData } from '~/components/modules/Tracking/helpers/trackingInjection';
import { AnalyticsEventMap, AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import { useIdentifyPosthogUser } from '~/hooks/tracking/useIdentifyPosthogUser';
import { useGoogleTagManager } from '~/hooks/useGoogleTagManager';

import { useAuthentication } from '../useAuthentication';
import { ImpactHelper } from '~/tracking/impact/ImpactHelper';

export type TrackingMetaData<T extends AnalyticsEventMap, K extends keyof AnalyticsEventMap> = T[K] | (() => T[K]);

export type CaptureEventTracking = <K extends keyof AnalyticsEventMap, T extends AnalyticsEventMap>(
    captureEventName: K,
    captureTrackingMetaData?: TrackingMetaData<T, K>
) => void;

/** Hook to track events. You may use the component ```TrackEvent``` or the callback ```captureEvent``` */
export function useTracking<T extends AnalyticsEventMap, K extends keyof AnalyticsEventMap>(
    eventName?: K,
    TrackingMetaData?: TrackingMetaData<T, K>
): {
    captureEvent: CaptureEventTracking;
    PosthogHelper: typeof PosthogHelper;
} {
    const { googleTagManager } = useGoogleTagManager();
    useIdentifyPosthogUser();

    const { userId: userID, email } = useAuthentication();

    /** Capture event available on clientside */
    const captureEvent = useCallback(
        (captureEventName: K, captureTrackingMetaData?: TrackingMetaData<T, K>) => {
            try {
                if (!eventName && !captureEventName) {
                    throw new Error('An event name must be specified');
                }
                const meta = handleMetaData(captureTrackingMetaData) || handleMetaData(TrackingMetaData);
                const event = captureEventName || eventName;
                const windowEvent = new CustomEvent(event, {
                    cancelable: false,
                    bubbles: true
                });
                window.dispatchEvent(windowEvent);

                const googleTrackingMetaData = meta || {};
                if (googleTagManager[event as string]) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    googleTagManager[event as string]({ userID, email, ...googleTrackingMetaData });
                } else {
                    void googleTagManager.dispatchEvent(event as string, { userID, email, ...meta });
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                PosthogHelper.captureEvent(event as keyof AnalyticsEventMap, meta as any);

                if (event === AnalyticsEventNames.UserSignup && userID) {
                    void ImpactHelper.postImpactUserSignup(userID);
                }

                SentryHelper.addBreadCrumb({
                    event_id: event as string,
                    data: meta
                });
            } catch (error) {
                console.error(error);
                SentryHelper.captureException(error);
            }
        },
        [eventName, TrackingMetaData, googleTagManager, userID, email]
    ) as CaptureEventTracking;

    return {
        captureEvent,
        PosthogHelper
    };
}
