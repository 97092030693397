/** Debounce a function to be delayed
 * @param fn - function you would like to throttle.
 * @param delay - how long you want to delay the function.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => void>(fn: T, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout> | null;
    // eslint-disable-next-line func-names
    return function (...args: Parameters<typeof fn>) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}
