import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const cancelOfferListing =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (tradeId: string): Promise<Response<void>> => {
        const headers = getHeaders(true);
        const cancelOfferEndpoint = getRequestUrl(`/v1/offer/portfolio/${getPortfolioId()}/trade/${tradeId}`);

        return httpRequest.delete(cancelOfferEndpoint, { headers });
    };
