import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const postUserLinkedBanks = ({ getHeaders, getUserId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (accountType: string): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/linkedBanks`);

        const result = await httpRequest.post(httpRequestUrl, {
            headers,
            body: JSON.stringify({ accountType })
        });
        return result;
    };
};
