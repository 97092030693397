import { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const cancelOrder =
    ({ getHeaders, getRequestUrl, getTradingPortfolioId }: MakeTradingRequestOptions) =>
    async (orderId: string): Promise<Response<void>> => {
        const headers = getHeaders(true);
        const getOrdersEndpoint = getRequestUrl(`/v1/portfolio/${getTradingPortfolioId()}/orders/${orderId}`);

        return httpRequest.delete(getOrdersEndpoint, { headers });
    };
