import { v4 as uuid } from 'uuid';

import { AnalyticsEventMap, AnalyticsEventNames, GoogleTagManager } from '~/constants/AnalyticsEventMap';
import { PaymentMethods } from '~/constants/PaymentMethods';
import { sha256HashString } from '~/helpers/SHA256HashString';
import { OktaAuthClient } from '~/utils/oktaAuthClient';
import { sendGTMEvent } from '@next/third-parties/google';

const defaultCookieConfig = { domain: '.vinovest.co', path: '/' };
export const EmailCaptureCookie = {
    KEY: 'emailcapture',
    CONFIG: { ...defaultCookieConfig }
};
interface DepositMetaData {
    value: number;
    method: string;
    frequency: string;
    id?: string;
}

export const GoogleTagManagerMapping = {
    [AnalyticsEventNames.CalendlyEventScheduled]: [GoogleTagManager.AppointmentScheduled],
    [AnalyticsEventNames.DepositInitiated]: [AnalyticsEventNames.DepositAdded],
    [AnalyticsEventNames.RegistrationComplete]: [AnalyticsEventNames.RegistrationComplete],
    [AnalyticsEventNames.IdentifyUser]: [GoogleTagManager.IdentifyUser],
    [AnalyticsEventNames.SelectPaymentMethod]: [GoogleTagManager.SelectFundSource],
    [AnalyticsEventNames.GoogleSignUpClick]: [GoogleTagManager.RegistrationComplete],
    [AnalyticsEventNames.AppleSignUpClick]: [GoogleTagManager.RegistrationComplete],
    [AnalyticsEventNames.EmailSignUpClick]: [GoogleTagManager.RegistrationComplete]
};

const sources = {
    bank_account_selected: 'bank_account_selected',
    credit_card_selected: 'credit_card_selected',
    crypto_selected: 'crypto_selected',
    mail_a_check_link_selected: 'mail_a_check_link_selected',
    bank_wire_selected: 'bank_wire_selected'
};

export class GTMClient {
    instance?: GTMClient;

    static build() {
        if (!this.prototype.instance) {
            const gtm = new GTMClient();
            gtm.addCalendly(gtm);
            this.prototype.instance = gtm;
        }

        return this.prototype.instance;
    }

    public [AnalyticsEventNames.GoogleSignUpClick]() {
        this.handleDispatchMapping(GoogleTagManagerMapping[AnalyticsEventNames.GoogleSignUpClick]);
    }

    public [AnalyticsEventNames.AppleSignUpClick]() {
        this.handleDispatchMapping(GoogleTagManagerMapping[AnalyticsEventNames.AppleSignUpClick]);
    }

    public [AnalyticsEventNames.EmailSignUpClick]() {
        this.handleDispatchMapping(GoogleTagManagerMapping[AnalyticsEventNames.EmailSignUpClick]);
    }

    public [AnalyticsEventNames.AddFunds]() {
        void this.dispatchEvent(AnalyticsEventNames.AddFunds);
    }

    public [AnalyticsEventNames.DepositInitiated](meta?: AnalyticsEventMap[AnalyticsEventNames.DepositInitiated]) {
        // Deposit added mapping needs to be removed after cleanup
        this[AnalyticsEventNames.DepositAdded](meta);
        void this.dispatchEvent(AnalyticsEventNames.DepositInitiated, meta);
    }

    public [AnalyticsEventNames.RegistrationComplete](
        meta?: AnalyticsEventMap[AnalyticsEventNames.RegistrationComplete]
    ) {
        void this.dispatchEvent(AnalyticsEventNames.RegistrationComplete, meta);
    }

    public [AnalyticsEventNames.QuizCompleted](meta?: AnalyticsEventMap[AnalyticsEventNames.QuizCompleted]) {
        void this.dispatchEvent(AnalyticsEventNames.QuizCompleted, meta);
    }

    public [AnalyticsEventNames.SetupManagedPortfolio](
        meta?: AnalyticsEventMap[AnalyticsEventNames.SetupManagedPortfolio]
    ) {
        void this.dispatchEvent(AnalyticsEventNames.SetupManagedPortfolio, meta);
    }

    public [AnalyticsEventNames.SetupTrading](meta?: AnalyticsEventMap[AnalyticsEventNames.SetupTrading]) {
        void this.dispatchEvent(AnalyticsEventNames.SetupTrading, meta);
    }

    public [AnalyticsEventNames.ViewCollections](meta?: AnalyticsEventMap[AnalyticsEventNames.ViewCollections]) {
        void this.dispatchEvent(AnalyticsEventNames.ViewCollections, meta);
    }

    public [AnalyticsEventNames.CompleteBuy](meta: AnalyticsEventMap[AnalyticsEventNames.CompleteBuy]) {
        void this.dispatchEvent(AnalyticsEventNames.CompleteBuy, meta);
    }

    public [AnalyticsEventNames.ConfirmSell](meta?: AnalyticsEventMap[AnalyticsEventNames.ConfirmSell]) {
        void this.dispatchEvent(AnalyticsEventNames.ConfirmSell, meta);
    }

    public [AnalyticsEventNames.LiquidationFeedback](
        meta?: AnalyticsEventMap[AnalyticsEventNames.LiquidationFeedback]
    ) {
        void this.dispatchEvent(AnalyticsEventNames.LiquidationFeedback, meta);
    }

    public [AnalyticsEventNames.LiquidationNoFeedback](
        meta?: AnalyticsEventMap[AnalyticsEventNames.LiquidationNoFeedback]
    ) {
        void this.dispatchEvent(AnalyticsEventNames.LiquidationNoFeedback, meta);
    }

    public [AnalyticsEventNames.LiquidationNewsletterOptIn](
        meta?: AnalyticsEventMap[AnalyticsEventNames.LiquidationNewsletterOptIn]
    ) {
        void this.dispatchEvent(AnalyticsEventNames.LiquidationNewsletterOptIn, meta);
    }

    public [AnalyticsEventNames.LiquidationFlowNewsletterCheckbox](
        meta?: AnalyticsEventMap[AnalyticsEventNames.LiquidationFlowNewsletterCheckbox]
    ) {
        void this.dispatchEvent(AnalyticsEventNames.LiquidationFlowNewsletterCheckbox, meta);
    }

    public [AnalyticsEventNames.DepositAdded](meta?: AnalyticsEventMap[AnalyticsEventNames.DepositInitiated]) {
        void this.dispatchEvent(AnalyticsEventNames.DepositAdded, meta);
        if (meta) {
            const depositMeta = {
                id: uuid(),
                method: meta.paymentSource,
                value: meta.depositAmount,
                frequency: meta.frequency
            };
            this.dispatchEcommerceTransaction(depositMeta);
        }
    }

    public [AnalyticsEventNames.CalendlyEventScheduled]() {
        this.handleDispatchMapping(GoogleTagManagerMapping[AnalyticsEventNames.CalendlyEventScheduled]);
    }

    public [AnalyticsEventNames.SelectPaymentMethod]({ paymentMethod }: { paymentMethod: string }) {
        switch (paymentMethod) {
            case PaymentMethods.CreditCard:
                this.trackCreditCardSelected();
                break;
            case PaymentMethods.Cryptocurrency:
                this.trackCryptoSelected();
                break;
            case PaymentMethods.BankAch:
                this.trackBankAccountSelected();
                break;
            case PaymentMethods.MailACheck:
                this.trackMailACheckLinkSelected();
                break;
            case PaymentMethods.BankWire:
                this.trackBankWireSelected();
                break;
            default:
        }
    }

    private async setUserId() {
        if (!this.userID) {
            try {
                const okta = await OktaAuthClient.getInstance();

                const { idToken } = await okta.tokenManager.getTokens();
                if (idToken) {
                    this.userID = idToken.claims.sub;
                }
                return undefined;
            } catch {
                return undefined;
            }
        }
        return undefined;
    }

    private async setEmail() {
        if (!this.email) {
            try {
                const okta = await OktaAuthClient.getInstance();

                const { idToken } = await okta.tokenManager.getTokens();
                if (idToken) {
                    this.email = idToken.claims.email ? idToken.claims.email : '';
                }
                return undefined;
            } catch {
                return undefined;
            }
        }
        return undefined;
    }

    public async dispatchEvent(name: string, attributes?: { [key: string]: unknown }) {
        await this.setUserId();
        await this.setEmail();
        const userId = { userID: this.userID };
        const email = { email: await sha256HashString(this.email) };
        const attributesWithUserId = attributes ? { ...attributes, ...userId, ...email } : { userId, email };
        sendGTMEvent({
            event: this.eventIdentifier,
            EVENT_NAME: name,
            ATTRS: attributesWithUserId
        });
    }

    /* currently only used for Google Analytics e-commerce transactions */
    private dispatchEcommerceTransaction(depositMeta: DepositMetaData) {
        const randomId = uuid();
        sendGTMEvent({
            event: this.eventIdentifier,
            EVENT_NAME: GoogleTagManager.EcommerceTransaction,
            transactionId: depositMeta.id,
            id: randomId,
            transactionTotal: depositMeta.value,
            transactionProducts: [
                {
                    name: depositMeta.method,
                    sku: depositMeta.frequency,
                    price: depositMeta.value,
                    quantity: 1
                }
            ]
        });
    }

    private handleDispatchMapping(events: string[], metaData?: any) {
        events.map((event) => this.dispatchEvent(event, metaData));
    }

    /** eslint-disable-next-line class-methods-use-this: o */
    private addCalendly(_gtm: any) {
        if (typeof window === 'undefined') {
            return;
        }

        window.addEventListener(
            'message',
            (msg) => {
                const calendlyEventScheduled = 'calendly.event_scheduled';
                if (msg?.data?.event === calendlyEventScheduled) {
                    void this.dispatchEvent(GoogleTagManager.AppointmentScheduled);
                }
            },
            false
        );
    }

    private trackBankAccountSelected() {
        void this.dispatchEvent(sources.bank_account_selected);
    }

    private trackCreditCardSelected() {
        void this.dispatchEvent(sources.credit_card_selected);
    }

    private trackCryptoSelected() {
        void this.dispatchEvent(sources.crypto_selected);
    }

    private trackMailACheckLinkSelected() {
        void this.dispatchEvent(sources.mail_a_check_link_selected);
    }

    private trackBankWireSelected() {
        void this.dispatchEvent(sources.bank_wire_selected);
    }

    [key: keyof AnalyticsEventMap | string]: any;

    private eventIdentifier = 'CUSTOM';

    private userID!: string;

    private email!: string;
}
