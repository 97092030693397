import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    html,
    body {
      margin: 0;
      height: 100%;
    }
    
    body {
      display: flex;
      flex-direction: column;
      font-family: var(--font-vinovest-medium);
      -webkit-font-smoothing: antialiased;
    }

    .skip-nav {
        background-color: #242E35;
        border: 1px solid #fff;
        color: #fff;
        display: none;
        height: 1px;
        left: -10000px;
        max-width: 200px;
        overflow: hidden;
        padding: 10px 20px;
        position: absolute;
        top: 0;
        width: 1px;
    }

    .skip-nav:focus {
        color: #fff;
        display: block;
        height: auto;
        left: 0;
        position: relative;
        width: auto;
        z-index: 9999;
    }

    .accessibilty-text {
        left: -9999px;
        position: absolute;
    }

    .animate-fade-hide {
      animation: fadeOut 700ms ease-in-out forwards;
    }

    @keyframes fadeOut { 0% { opacity: 1; visibility: visible; } 100% { opacity: 0; visibility: hidden; display: none;  }} 

`;
