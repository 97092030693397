import { GetUserProfileResponse } from '~/networking/api/managed';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getUserProfile =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetUserProfileResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/profile`);

        return httpRequest.get(httpRequestUrl, { headers });
    };
