import Color from 'color';
import media from 'styled-media-query';

import { primitives } from './color-palette';

// const breakpoints = ['0px', '600px', '960px', '1280px', '1920px'];

const palette = {
    primitives,
    foundation: {
        primaryA: primitives.platinum['800'],
        primaryB: primitives.white,
        accent: primitives.brown['600'],
        positive: primitives.green['600'],
        warning: primitives.yellow['600'],
        negative: primitives.red['600']
    },
    background: {
        primary: primitives.white,
        secondary: primitives.gray['200'],
        tertiary: primitives.blue['100'],
        inverseSecondary: primitives.platinum['700'],
        stateDisabled: primitives.gray['200'],
        overlayDark: Color(primitives.black).lightness(0.3),
        overlayLight: Color(primitives.black).lightness(0.08),
        negative: primitives.red['600'],
        warning: primitives.yellow['600'],
        positive: primitives.green['600'],
        lightAccent: primitives.brown['200'],
        lightNegative: primitives.red['200'],
        lightWarning: primitives.yellow['200'],
        lightPositive: primitives.green['200'],
        alwaysDark: primitives.platinum['800'],
        alwaysLight: primitives.white
    },
    content: {
        primary: primitives.platinum['800'],
        secondary: primitives.gray['500'],
        tertiary: primitives.gray['400'],
        inversePrimary: primitives.white,
        inverseSecondary: primitives.gray['300'],
        inverseTertiary: primitives.gray['400'],
        stateDisabled: primitives.gray['300'],
        onColor: primitives.white,
        accent: primitives.brown['600'],
        negative: primitives.red['600'],
        warning: primitives.yellow['700'],
        positive: primitives.green['600'],
        buttonTertiary: '#CACCCE'
    },
    border: {
        opaque: primitives.gray['300'],
        disabled: primitives.gray['200'],
        accent: primitives.brown['600'],
        accentLight: primitives.brown['300'],
        negative: primitives.red['400'],
        warning: primitives.yellow['400'],
        positive: primitives.green['400']
    },
    primary: {},
    secondary: {},
    text: {
        primary: '#fff',
        secondary: '#AAB4BE',
        disabled: 'rgba(255, 255, 255, 0.5)',
        icon: 'rgba(255, 255, 255, 0.5)'
    },
    common: {
        black: '#000000',
        white: '#ffffff'
    }
};

const fontFamily = {
    VinovestMono: 'var(--font-vinovest-mono)',
    VinovestMedium: 'var(--font-vinovest-medium)',
    RoslindaleDisplayCondensed: 'var(--font-roslindale)',
    Favoritstd: 'var(--font-favoritstd)',
    Favoritmonostd: 'var(--font-favoritmonostd)'
};

const typographyScale = {
    display1: {
        lg: {
            fontSize: 96,
            fontWeight: 'normal',
            lineHeight: '112px',
            textTransform: 'capitalize'
        },
        md: {
            fontSize: 64,
            fontWeight: 'normal',
            lineHeight: '84px',
            textTransform: 'capitalize'
        },
        sm: {
            fontSize: 44,
            fontWeight: 'normal',
            lineHeight: '64px',
            textTransform: 'capitalize'
        },
        xs: {
            fontSize: 36,
            fontWeight: 'normal',
            lineHeight: '54px',
            textTransform: 'capitalize'
        }
    },
    heading1: {
        xl: {
            fontSize: 45,
            fontWeight: 'normal',
            lineHeight: '60px',
            textTransform: 'capitalize'
        },
        lg: {
            fontSize: 36,
            fontWeight: 'normal',
            lineHeight: '40px',
            textTransform: 'capitalize'
        },
        md: {
            fontSize: 24,
            fontWeight: 'normal',
            lineHeight: '34px',
            textTransform: 'capitalize'
        },
        sm: {
            fontSize: 20,
            fontWeight: 'normal',
            lineHeight: '32px',
            textTransform: 'capitalize'
        },
        xs: {
            fontSize: 16,
            fontWeight: 'normal',
            lineHeight: '28px',
            textTransform: 'capitalize'
        },
        xxs: {
            fontSize: 14,
            fontWeight: 'normal',
            lineHeight: '24px',
            textTransform: 'capitalize'
        }
    },
    body1: {
        lg: {
            fontSize: 18,
            lineHeight: '34px'
        },
        md: {
            fontSize: 16,
            lineHeight: '30px'
        },
        sm: {
            fontSize: 14,
            lineHeight: '26px'
        },
        xs: {
            fontSize: 12,
            lineHeight: '24px'
        }
    },
    body2: {
        lg: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: '30px'
        },
        md: {
            fontSize: 18,
            fontWeight: 500,
            lineHeight: '28px'
        },
        sm: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '26px'
        },
        xs: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '24px'
        }
    },
    label1: {
        lg: {
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '40px'
        },
        md: {
            fontSize: 18,
            fontWeight: 500,
            lineHeight: '26px'
        },
        sm: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px'
        },
        xs: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '22px'
        },
        xxs: {
            fontSize: 11,
            fontWeight: 500,
            lineHeight: '20px'
        }
    },
    label2: {
        lg: {
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '40px'
        },
        md: {
            fontSize: 18,
            fontWeight: 500,
            lineHeight: '26px'
        },
        sm: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px'
        },
        xs: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '22px'
        },
        xxs: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '20px'
        }
    }
};
const typographyDefault = {
    body1: {
        fontFamily: fontFamily.VinovestMedium,
        color: palette.foundation.primaryA
    },
    body2: {
        fontFamily: fontFamily.VinovestMedium,
        color: primitives.neutrals['500']
    },
    label1: {
        fontFamily: fontFamily.VinovestMono,
        color: primitives.neutrals['600'],
        textTransform: 'uppercase'
    },
    label2: {
        fontFamily: fontFamily.VinovestMono,
        color: primitives.neutrals['900'],
        textTransform: 'uppercase'
    },
    heading1: {
        fontFamily: fontFamily.RoslindaleDisplayCondensed,
        color: palette.foundation.primaryA,
        textTransform: 'capitalize'
    },
    display1: {
        fontFamily: fontFamily.RoslindaleDisplayCondensed,
        color: palette.foundation.primaryA
    }
};
const typography = {
    family: {
        ...fontFamily
    },
    h1: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.xl
    },
    h2: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.lg
    },
    h3: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.md
    },
    h4: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.sm
    },
    h5: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.xs
    },
    h6: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.xxs
    },
    display1lg: {
        ...typographyDefault.display1,
        ...typographyScale.display1.lg
    },
    display1md: {
        ...typographyDefault.display1,
        ...typographyScale.display1.md
    },
    display1sm: {
        ...typographyDefault.display1,
        ...typographyScale.display1.sm
    },
    display1xs: {
        ...typographyDefault.display1,
        ...typographyScale.display1.xs
    },
    display1: {
        ...typographyDefault.display1,
        ...typographyScale.display1.md
    },
    heading1: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.xl
    },
    heading1xl: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.xl
    },
    heading1lg: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.lg
    },
    heading1md: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.md
    },
    heading1sm: {
        ...typographyDefault.heading1,
        ...typographyScale.heading1.sm
    },
    body1: {
        ...typographyDefault.body1,
        ...typographyScale.body1.sm
    },
    body1lg: {
        ...typographyDefault.body1,
        ...typographyScale.body1.lg
    },
    body1md: {
        ...typographyDefault.body1,
        ...typographyScale.body1.md
    },
    body1sm: {
        ...typographyDefault.body1,
        ...typographyScale.body1.sm
    },
    body1xs: {
        ...typographyDefault.body1,
        ...typographyScale.body1.xs
    },
    body2: {
        ...typographyDefault.body2,
        ...typographyScale.body2.sm
    },
    body2lg: {
        ...typographyDefault.body2,
        ...typographyScale.body2.lg
    },
    body2md: {
        ...typographyDefault.body2,
        ...typographyScale.body2.md
    },
    body2sm: {
        ...typographyDefault.body2,
        ...typographyScale.body2.sm
    },
    body2xs: {
        ...typographyDefault.body2,
        ...typographyScale.body2.xs
    },
    label1: {
        ...typographyDefault.label1,
        ...typographyScale.label1.sm
    },
    label1lg: {
        ...typographyDefault.label1,
        ...typographyScale.label1.lg
    },
    label1md: {
        ...typographyDefault.label1,
        ...typographyScale.label1.md
    },
    label1sm: {
        ...typographyDefault.label1,
        ...typographyScale.label1.sm
    },
    label1xs: {
        ...typographyDefault.label1,
        ...typographyScale.label1.xs
    },
    label1xxs: {
        ...typographyDefault.label1,
        ...typographyScale.label1.xxs
    },
    label2: {
        ...typographyDefault.label2,
        ...typographyScale.label2.sm
    },
    label2lg: {
        ...typographyDefault.label2,
        ...typographyScale.label2.lg
    },
    label2md: {
        ...typographyDefault.label2,
        ...typographyScale.label2.md
    },
    label2sm: {
        ...typographyDefault.label2,
        ...typographyScale.label2.sm
    },
    label2xs: {
        ...typographyDefault.label2,
        ...typographyScale.label2.xs
    },
    label2xxs: {
        ...typographyDefault.label2,
        ...typographyScale.label2.xxs
    },
    caption: {
        ...typographyDefault.body1,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '20px'
    },
    tooltip: {
        ...typographyDefault.body1,
        fontSize: 11,
        fontWeight: 500,
        lineHeight: '18px'
    }
};

const styles = {
    shadow: {
        elevation1: '0px 4px 20px rgba(0, 0, 0, 0.1)'
    },
    borderRadius: 8
};

export const theme = {
    // breakpoints,
    styles,
    palette,
    typography,
    media
};
declare module 'styled-components' {
    export interface DefaultTheme {
        styles: (typeof theme)['styles'];
        palette: (typeof theme)['palette'];
        typography: (typeof theme)['typography'];
        media: (typeof theme)['media'];
    }
}
