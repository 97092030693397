import { GetUserHasSubscriptionsResponse } from '~/networking';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getUserHasSubscriptions =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (portfolioID: string): Promise<Response<GetUserHasSubscriptionsResponse>> => {
        const headers = getHeaders(true);
        const getUserHasSubscriptionsEndpoint = getRequestUrl(
            `/v1/payment/${getUserId()}/portfolio/${portfolioID}/subscription`
        );

        return httpRequest.get(getUserHasSubscriptionsEndpoint, { headers });
    };
