import { CreatePortfolioResponse } from '~/networking/api/trading/responses';
import { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const createTradingPortfolio =
    ({ getHeaders, getRequestUrl, getUserId }: MakeTradingRequestOptions) =>
    async (): Promise<Response<CreatePortfolioResponse>> => {
        const createPortfolioEndpoint = getRequestUrl(`/v1/portfolio`);
        const headers = getHeaders(true);
        const createTradingPortfolioPayload = {
            headers,
            body: { userID: getUserId() }
        };

        return httpRequest.post(createPortfolioEndpoint, { body: createTradingPortfolioPayload });
    };
