import type { GetUserPortfolioResponse } from '~/networking/api/managed';
import type { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { PortfolioPerformanceRanges } from '~/networking/models/PortfolioPerformanceRanges';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getUserPortfolio =
    ({ getHeaders, getRequestUrl, getUserId, getPortfolioId }: MakeMangedRequestOptions) =>
    async (range: PortfolioPerformanceRanges, portfolioId?: string): Promise<Response<GetUserPortfolioResponse>> => {
        const usableID = portfolioId || getPortfolioId() || getUserId();
        const headers = getHeaders(true);
        const getUserPortfolioEndpoint = getRequestUrl(`/v1/portfolio/${usableID}?range=${range}`);

        return httpRequest.get(getUserPortfolioEndpoint, { headers });
    };
