import { CancelTradingPortfolioOrderByIdResponse } from '~/networking/api/trading/responses';
import { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const cancelTradingPortfolioOrderById =
    ({ getHeaders, getTradingPortfolioId, getRequestUrl }: MakeTradingRequestOptions) =>
    async (orderId: string): Promise<Response<CancelTradingPortfolioOrderByIdResponse>> => {
        const cancelTradingPortfolioOrderByIdEndpoint = getRequestUrl(
            getRequestUrl(`/v1/portfolio/${getTradingPortfolioId()}/orders/${orderId}}`)
        );

        return httpRequest.delete(cancelTradingPortfolioOrderByIdEndpoint, { headers: getHeaders(true) });
    };
