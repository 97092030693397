import { GetBankWireKeyReferenceResponse } from '~/networking/api/managed';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getBankWireKeyReference =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetBankWireKeyReferenceResponse>> => {
        const headers = getHeaders(true);
        const getBankWireKeyReferenceEndpoint = getRequestUrl(`/v1/user/${getUserId()}/key`);

        return httpRequest.get(getBankWireKeyReferenceEndpoint, { headers });
    };
