import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export interface PutAllocationClassificationPayload {
    allocationClassification: 'lots' | 'casks' | 'scotch' | null;
}

export const putAllocationClassification = ({ getHeaders, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (updater: {
        payload: PutAllocationClassificationPayload;
        portfolioID: string;
    }): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/portfolio/${updater.portfolioID}/allocationClassification`);

        const result = await httpRequest.put(httpRequestUrl, {
            headers,
            body: JSON.stringify(updater.payload)
        });
        return result;
    };
};
