export enum PortfolioChargesStatus {
    Pending = 'pending'
}

interface PortfolioCharge {
    money: {
        amount: number;
        currency: string;
    };
    paymentMethod: {
        achDebit: {
            name: string;
        };
        card: {
            brand: string;
            expMonth: number;
            expYear: number;
            last4: string;
        };
        id: string;
    };
    status: string;
}

export type PortfolioCharges = PortfolioCharge[];
