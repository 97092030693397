import { GetPortfolioWineResponse } from '~/networking/api/managed';
import { MakeMangedRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { PortfolioPerformanceRanges } from '~/networking/models';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getPortfolioWine =
    ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) =>
    async (lwin18: string, range: PortfolioPerformanceRanges): Promise<Response<GetPortfolioWineResponse>> => {
        const headers = getHeaders(true);
        const getPortfolioWineEndpoint = getRequestUrl(
            `/v1/portfolio/${getPortfolioId()}/wines/${lwin18}?range=${range}`
        );

        return httpRequest.get(getPortfolioWineEndpoint, { headers });
    };
