import { GetTradingPortfolioResponse } from '~/networking/api/trading/responses';
import { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getTradingPortfolio =
    ({ getHeaders, getRequestUrl, getTradingPortfolioId }: MakeTradingRequestOptions) =>
    async (): Promise<Response<GetTradingPortfolioResponse>> => {
        const headers = getHeaders(true);
        const getTradingPortfolioEndpoint = getRequestUrl(`/v1/portfolio/${getTradingPortfolioId()}`);

        return httpRequest.get(getTradingPortfolioEndpoint, { headers });
    };
