import { GetAccountStatementMonthsResponse } from '~/networking/api';
import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export const getAccountStatementMonths = ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) => {
    return async (): Promise<Response<GetAccountStatementMonthsResponse>> => {
        const headers = getHeaders(true);
        const endpoint = getRequestUrl(`/v2/documents/monthly/${getPortfolioId()}/filters`);
        return httpRequest.get<GetAccountStatementMonthsResponse>(endpoint, { headers });
    };
};
