import { MakeMangedRequestOptions } from '~/networking/client';
import { InvestmentStrategy } from '~/networking/constants';
import { httpRequest, Response } from '~/networking/utils';

export interface PutUpdateInvestmentPlanPayload {
    investmentStyle: Lowercase<InvestmentStrategy>;
    horizon: string;
}

export const putUpdateInvestmentPlan = ({ getHeaders, getPortfolioId, getRequestUrl }: MakeMangedRequestOptions) => {
    return async (updater: PutUpdateInvestmentPlanPayload): Promise<Response<unknown>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/portfolio/${getPortfolioId()}/settings`);

        const result = await httpRequest.put(httpRequestUrl, {
            headers,
            body: JSON.stringify(updater)
        });
        return result;
    };
};
