import { MakeMangedRequestOptions } from '~/networking/client';
import { Response, httpRequest } from '~/networking/utils';
import { GetUserLinkedBanksResponse } from '../../responses/users/GetUserLinkedBanksResponse';

export const getUserLinkedBanks =
    ({ getHeaders, getRequestUrl, getUserId }: MakeMangedRequestOptions) =>
    async (): Promise<Response<GetUserLinkedBanksResponse[]>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/v1/user/${getUserId()}/linkedBanks`);

        return httpRequest.get(httpRequestUrl, { headers });
    };
