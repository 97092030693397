import type { GetTransactionsResponse } from '~/networking/api/trading/responses';
import type { MakeTradingRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import type { FeeTypes } from '~/networking/models';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export interface GetTransactionParams {
    pageSize?: number;
    pageToken?: string;
    type?: FeeTypes;
}

export const getTransactions =
    ({ getHeaders, getRequestUrl, getTradingPortfolioId }: MakeTradingRequestOptions) =>
    async (params: GetTransactionParams): Promise<Response<GetTransactionsResponse>> => {
        const headers = getHeaders(true);
        const urlParams = new URLSearchParams({
            pageSize: String(params.pageSize || ''),
            pageToken: params.pageToken || '',
            type: params.type || ''
        });
        const getOrdersEndpoint = getRequestUrl(
            `/v2/portfolio/${getTradingPortfolioId()}/transactions?${urlParams.toString()}`
        );

        return httpRequest.get(getOrdersEndpoint, { headers });
    };
