/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, type ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export const initialModalRouterContext: ModalRouterContextContent = {
    value: {
        collection: '',
        slug: '',
        vintage: '',
        volume: '',
        lwin: ''
    },
    event: null,
    open: false,
    handleModalData: (): void => {},
    setOpen: (): void => {}
};

export interface ModalRouterContextContent {
    value: {
        collection?: string;
        slug?: string;
        vintage?: string;
        volume?: string;
        lwin?: string;
    };
    event: any;
    open: boolean;
    handleModalData: (data: Record<string, any>) => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalRouterContext = createContext(initialModalRouterContext);

ModalRouterContext.displayName = 'ModalRouterContext';

/**
 * Context holds contextual navigation items for modal router.
 */
export const ModalRouterContextProvider: React.FC<{ children?: ReactNode | undefined }> = ({ children }) => {
    const context = useContext(ModalRouterContext);
    const [value, setValue] = useState(context);
    const [open, setOpen] = useState(false);

    const handleModalData = useCallback(
        (modalData: Partial<ModalRouterContextContent>) => {
            setValue({ ...value, ...modalData });
            setOpen(!open);
        },
        [open, value]
    );

    const contextValue = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return { handleModalData, value, open, setOpen, event: value.event };
    }, [handleModalData, open, value]);

    return (
        <ModalRouterContext.Provider value={contextValue as ModalRouterContextContent}>
            {children}
        </ModalRouterContext.Provider>
    );
};
