import { QueryClient } from 'react-query';

import { DepositEventListeners } from './EventListeners/DepositEventListeners';
import { TradingEventListeners } from './EventListeners/TradingEventListeners';

export const GlobalEventListeners = ({ queryClient }: { queryClient: QueryClient }) => {
    return (
        <>
            <TradingEventListeners queryClient={queryClient} />
            <DepositEventListeners queryClient={queryClient} />
        </>
    );
};
