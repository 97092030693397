import { useCallback, useEffect } from 'react';
import { QueryClient } from 'react-query';

import { ApplicationEventNames } from '~/constants/ApplicationEvents';
import { queries } from '~/constants/queryKeys';

const tradingQueries = [
    queries.getAssetDetails,
    queries.getLastTradePrice,
    queries.getMarketPrice,
    queries.getMarketOrders,

    queries.getTradingPortfolio,
    queries.getTradingTransactions,
    queries.getUserPortfolioOrders
];
export const TradingEventListeners = ({ queryClient }: { queryClient: QueryClient }) => {
    const updateTrading = useCallback(async () => {
        await Promise.all(tradingQueries.map((key) => queryClient.invalidateQueries([key])));
    }, [queryClient]);

    useEffect(() => {
        window.addEventListener(ApplicationEventNames.OrderCanceled, updateTrading);
        window.addEventListener(ApplicationEventNames.TradeInitiated, updateTrading);
        window.addEventListener(ApplicationEventNames.DepositModalClosed, updateTrading);

        return () => {
            window.removeEventListener(ApplicationEventNames.OrderCanceled, updateTrading);
            window.removeEventListener(ApplicationEventNames.TradeInitiated, updateTrading);
            window.removeEventListener(ApplicationEventNames.DepositModalClosed, updateTrading);
        };
    }, []);

    return <>{null}</>;
};
